import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useUserContext } from 'components';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';

import { SubmissionOutletContextValue } from '../../types';

import PreviousSubmissions from './PreviousSubmissions/PreviousSubmissions';
import Results from './Results';

import styles from './reports.module.scss';

const Reports = () => {
    const { isHesa } = useUserContext();
    const { data, collection, collectionId, submissionId, setPageTitle } =
        useOutletContext<SubmissionOutletContextValue>();
    const instId = data?.provider?.instId;

    useEffect(() => {
        setPageTitle('Quality Assurance');
    }, [setPageTitle]);

    return data && data.fileName ? (
        <div key={data.fileName}>
            {isHesa && (
                <PreviousSubmissions
                    collectionId={collectionId ?? ''}
                    instId={instId ?? ''}
                />
            )}

            <span className={styles.fileName}>{data.fileName}</span>
            <ErrorBoundary>
                <Results
                    data={data}
                    collection={collection}
                    collectionId={collectionId ? collectionId : ''}
                    submissionId={submissionId ? submissionId : ''}
                />
            </ErrorBoundary>
        </div>
    ) : (
        <React.Fragment>
            <span>Quality assurance</span>
            <Typography>
                You have yet to upload a file. Please upload one on the upload
                step.
            </Typography>
        </React.Fragment>
    );
};

export default Reports;
