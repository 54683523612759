import React from 'react';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import { TABLE_PARAMS_IDS } from 'constants/constants';
import useTableParams from 'hooks/useTableParams/useTableParams';
import { createDownloadLinkAndClickIt } from 'pages/Collections/OnlineValidation/utils';
import useCredibilityCellDownload from 'queries/credibility/useCredibilityCellDownload';
import useCredibilityDrilldownData from 'queries/credibility/useCredibilityDrilldownData';
import { CredibilityReportDrilldown } from 'types/credibility';

import CredibilityDrilldownTable from './CredibilityDrilldownTable';

interface CredibilityDrilldownPopupProps {
    isOpen: boolean;
    onClose: () => void;
    cellId: string;
    submissionId: string;
    reportCode: string;
}
export default function CredibilityDrilldownPopup({
    isOpen,
    cellId,
    submissionId,
    reportCode,
    onClose,
}: CredibilityDrilldownPopupProps) {
    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
        sortBy: '',
        sortOrder: 'desc',
    };

    const tableId = TABLE_PARAMS_IDS.CREDIBILITY_REPORT_DRILLDOWN_TABLE;

    const { values: tableParams, methods: tableMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { limit, offset, sortBy, sortOrder } = tableParams;
    const { setLimit, setOffset, setSortBy, setSortOrder } = tableMethods;

    const [showLoading, setShowLoading] = React.useState(false);

    const isAbleToFetchDrillDownData = Boolean(cellId) && isOpen;

    const [lastKnownTitle, setLastKnownTitle] =
        React.useState('Drilldown Data');
    const [stableData, setStableData] =
        React.useState<CredibilityReportDrilldown | null>(null);

    const { data: drilldownData, isFetching: isFetchingDrilldownData } =
        useCredibilityDrilldownData({
            cellId,
            enabled: isAbleToFetchDrillDownData,
            sortField: sortBy,
            sortOrder,
            submissionId,
            reportCode,
            limit,
            offset,
        });

    const { data: cellDownloadData } = useCredibilityCellDownload({
        submissionId,
        reportCode,
        cellId,
        enabled: isAbleToFetchDrillDownData,
    });

    React.useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;
        if (isFetchingDrilldownData && !sortBy) {
            // Only show loading on initial load
            timeoutId = setTimeout(() => {
                setShowLoading(true);
            }, 150);
        } else {
            setShowLoading(false);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isFetchingDrilldownData, sortBy]);

    React.useEffect(() => {
        if (drilldownData && !isFetchingDrilldownData) {
            setStableData(drilldownData);
            if (drilldownData.DrillDown?.UpperNotes) {
                setLastKnownTitle(drilldownData.DrillDown.UpperNotes);
            }
        }
    }, [drilldownData, isFetchingDrilldownData]);

    const onClickConfirm = () => {
        if (cellDownloadData) {
            createDownloadLinkAndClickIt(
                cellDownloadData.href,
                cellDownloadData.download,
            );
        }
    };

    const onCancel = () => {
        setOffset(0);
        setLimit(10);
        setSortBy('');
        setSortOrder('desc');
        setLastKnownTitle('Drilldown Data');
        setStableData(null);
        onClose();
    };

    const handleSort = (column: string) => {
        let newSortOrder = sortOrder;
        if (sortBy === column) {
            if (sortOrder === 'asc') {
                newSortOrder = 'desc';
            } else {
                newSortOrder = 'asc';
            }
        }

        setSortOrder(newSortOrder);
        setSortBy(column);
        setOffset(0);
    };

    const pageHandler = (offset: number, limit: number) => {
        setOffset(offset);
        setLimit(limit);
    };

    const displayData = stableData || {
        DrillDown: {
            Headers: [],
            Data: [],
            Id: '',
            ReportCode: reportCode,
            UpperNotes: 'Drilldown Data',
        },
        PagingMetadata: {
            Total: 0,
            Limit: 10,
            Offset: 0,
            Count: 0, // Added required Count field
        },
    };

    return (
        <ConfirmationPopup
            isLoading={showLoading}
            onCancel={onCancel}
            onConfirm={onClickConfirm}
            isOpen={isOpen}
            title={lastKnownTitle}
            size="lg"
            customConfirmButtonText="Download to CSV"
            isConfirmButtonHidden={
                !cellDownloadData?.href || !cellDownloadData?.download
            }
        >
            <CredibilityDrilldownTable
                data={displayData}
                onSort={handleSort}
                pageHandler={pageHandler}
                sortBy={sortBy}
                sortOrder={sortOrder as 'asc' | 'desc'}
                isLoading={showLoading}
            />
        </ConfirmationPopup>
    );
}
