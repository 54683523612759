import { queryOptions } from '@tanstack/react-query';
import { additionalReports } from 'services/api';
import { GetFrequencyCountReportRequestArgs } from 'services/api/additionalReports/types';

export function getAdditionalReportsQueryOptions({
    submissionId,
}: {
    submissionId: string;
}) {
    return queryOptions({
        queryKey: ['additional-reports', 'list', submissionId],
        queryFn: () => additionalReports.getReports({ submissionId }),
    });
}

export function getFrequencyCountsReportQueryOptions({
    submissionId,
    hideZeroValues,
}: GetFrequencyCountReportRequestArgs) {
    return queryOptions({
        queryKey: [
            'additional-reports',
            'frequency-counts',
            submissionId,
            hideZeroValues,
        ],
        queryFn: () =>
            additionalReports.getFrequencyCountsReport({
                submissionId,
                hideZeroValues,
            }),
    });
}

export function getPGRTransfersOutReportQueryOptions({
    submissionId,
}: {
    submissionId: string;
}) {
    return queryOptions({
        queryKey: ['additional-reports', 'pgr-transfers-out', submissionId],
        queryFn: () =>
            additionalReports.getPGRTransfersOutReport({ submissionId }),
    });
}

export function getPGRTransfersOutConsentStatusQueryOptions({
    submissionId,
}: {
    submissionId: string;
}) {
    return queryOptions({
        queryKey: [
            'additional-reports',
            'pgr-transfers-out-consent',
            submissionId,
        ],
        queryFn: () =>
            additionalReports.getPGRTransfersOutConsentStatus({ submissionId }),
    });
}

export function getPGRTransfersInReportQueryOptions({
    submissionId,
}: {
    submissionId: string;
}) {
    return queryOptions({
        queryKey: ['additional-reports', 'pgr-transfers-in', submissionId],
        queryFn: () =>
            additionalReports.getPGRTransfersInReport({ submissionId }),
    });
}

export function getPGRTransfersOutDownloadQueryOptions({
    submissionId,
    instId,
}: {
    submissionId: string;
    instId: string;
}) {
    return queryOptions({
        queryKey: [
            'additional-reports',
            'pgr-transfers-out-download',
            submissionId,
            instId,
        ],
        queryFn: () =>
            additionalReports.getPGRTransfersOutDownload({
                submissionId,
                instId,
            }),
    });
}

export function getPGRTransfersInDownloadQueryOptions({
    submissionId,
    instId,
}: {
    submissionId: string;
    instId: string;
}) {
    return queryOptions({
        queryKey: [
            'additional-reports',
            'pgr-transfers-in-download',
            submissionId,
            instId,
        ],
        queryFn: () =>
            additionalReports.getPGRTransfersInDownload({
                submissionId,
                instId,
            }),
    });
}

export function getContinuityPopulationReportQueryOptions({
    submissionId,
}: {
    submissionId: string;
}) {
    return queryOptions({
        queryKey: ['additional-reports', 'continuity-population', submissionId],
        queryFn: () =>
            additionalReports.getContinuityPopulationReport({ submissionId }),
    });
}

export function getIrisReportQueryOptions({
    submissionId,
}: {
    submissionId: string;
}) {
    return queryOptions({
        queryKey: ['additional-reports', 'iris', submissionId],
        queryFn: () => additionalReports.getIrisReport({ submissionId }),
    });
}

export function getIrisReprocessFilesQueryOptions() {
    return queryOptions({
        queryKey: ['additional-reports', 'iris-reprocess-files'],
        queryFn: () => additionalReports.getIrisReprocessFiles(),
    });
}

export function getNSSReportQueryOptions({
    submissionId,
}: {
    submissionId: string;
}) {
    return queryOptions({
        queryKey: ['additional-reports', 'nss', submissionId],
        queryFn: () => additionalReports.getNSSReport({ submissionId }),
    });
}

export function getCCAReportXlsxQueryOptions({
    submissionId,
}: {
    submissionId: string;
}) {
    return queryOptions({
        queryKey: ['additional-reports', 'cca', submissionId],
        queryFn: () => additionalReports.getCCAReportXlsx({ submissionId }),
    });
}

export function getComparisonReportQueryOptions({
    submissionId,
}: {
    submissionId: string;
}) {
    return queryOptions({
        queryKey: ['additional-reports', 'comparison', submissionId],
        queryFn: () => additionalReports.getComparisonReport({ submissionId }),
    });
}
