function sortComparator<Type extends object, Key extends keyof Type>(
    a: Type,
    b: Type,
    orderBy: Key,
) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export default function useSort<Type extends object, Key extends keyof Type>(
    data: Type[] | undefined,
    order: 'desc' | 'asc',
    orderBy: Key,
): Type[] {
    if (data === undefined) return [];

    const comparator =
        order === 'desc'
            ? (a: Type, b: Type) => sortComparator(a, b, orderBy)
            : (a: Type, b: Type) => -sortComparator(a, b, orderBy);

    const sortedData = Array.from(data).sort(comparator);
    return sortedData || [];
}
