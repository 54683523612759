import React from 'react';
import Accordion from 'components/Accordion/Accordion';

export interface AccordionSecondaryProps {
    id: string;
    children: React.ReactNode;
    title: string;
    isOpenByDefault?: boolean;
    dataTestId: string;
}

const AccordionSecondary = ({
    id,
    children,
    title,
    isOpenByDefault,
    dataTestId,
}: AccordionSecondaryProps) => {
    return (
        <Accordion
            id={id}
            title={title}
            isOpenByDefault={isOpenByDefault}
            type="secondary"
            dataTestId={dataTestId}
        >
            {children}
        </Accordion>
    );
};

export default AccordionSecondary;
