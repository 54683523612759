import { useQuery } from '@tanstack/react-query';
import { GetReportsRequestArgs } from 'services/api/credibility/types';

import { getCredibilityReportsQueryOptions } from './options';

export default function useCredibilityReports({
    submissionId,
    providerId,
}: GetReportsRequestArgs) {
    const options = getCredibilityReportsQueryOptions({
        submissionId,
        providerId,
    });
    return useQuery(options);
}
