import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Box,
    Paper,
    TablePagination,
    TablePaginationProps,
    Typography,
} from '@mui/material';
import {
    HDPAccordion,
    HDPAccordionDetails,
    HDPAccordionGroup,
    HDPAccordionSummary,
} from 'components/HDPAccordion';
import Loading from 'components/Loading/Loading';
import WithTestId from 'components/WithTestId/WithTestId';
import { DerivedFieldInterface } from 'queries/specifications/types';
import { ACCORDION_IDS } from 'src/constants/constants';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { PagingMetadata } from 'types/api';

import { FilterForm } from '../FilterForm';
import LoadingFailed from '../LoadingFailed/LoadingFailed';

const TablePaginationWithTestId =
    WithTestId<TablePaginationProps>(TablePagination);

interface DerivedFieldsProps {
    data: {
        derivedFields: DerivedFieldInterface[];
        pagingMetadata: PagingMetadata;
    };
    handleChangeRowsPerPage: (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => void;
    handleChangePage: (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => void;
    handleFilter: (filter: { filterTerm: string }) => void;
    handleTryAgain: () => void;
    derivedFieldParams: {
        filter: string;
        page: number;
        rowsPerPage: number;
    };
    status: 'pending' | 'error' | 'success';
}

export const DerivedFields = ({
    data,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilter,
    handleTryAgain,
    derivedFieldParams,
    status,
}: DerivedFieldsProps) => {
    const derivedFields = data?.derivedFields;
    const total = data?.pagingMetadata?.total;
    switch (status) {
        case 'pending':
            return <Loading />;

        case 'error':
            return (
                <LoadingFailed
                    onTryAgain={handleTryAgain}
                    message="There was a problem loading the list of derived fields."
                />
            );

        case 'success':
            return (
                <section>
                    <Paper square>
                        <Box p={3}>
                            <FilterForm
                                initialFilterTerm={derivedFieldParams.filter}
                                onFilter={handleFilter}
                            />
                        </Box>
                    </Paper>
                    <HDPAccordionGroup spread={false}>
                        {derivedFields.map((derivedField, i) => (
                            <HDPAccordion
                                square
                                size="xs"
                                noBorder
                                elevated
                                searchParamId={
                                    ACCORDION_IDS.SPECIFICATION.DERIVED_FIELD +
                                    derivedField.id
                                }
                                key={`derived-field-${derivedField.id}`}
                            >
                                <HDPAccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    data-test-id={formatTestId(
                                        'open derived field',
                                        (i + 1).toString(),
                                    )}
                                    color="black"
                                    fontWeight="normal"
                                    header={
                                        <Typography
                                            variant={'body1'}
                                            sx={{ marginLeft: 2 }}
                                        >
                                            {derivedField.name}
                                        </Typography>
                                    }
                                />
                                <HDPAccordionDetails>
                                    <Typography variant={'body2'}>
                                        {derivedField.description}
                                    </Typography>
                                </HDPAccordionDetails>
                            </HDPAccordion>
                        ))}
                    </HDPAccordionGroup>
                    <TablePaginationWithTestId
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={total || 0}
                        rowsPerPage={derivedFieldParams.rowsPerPage}
                        page={derivedFieldParams.page}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        testIdProps={{
                            backIconButtonProps: {
                                'data-test-id': 'previousPage',
                            },
                            nextIconButtonProps: {
                                'data-test-id': 'nextPage',
                            },
                        }}
                    />
                </section>
            );

        default:
            return null;
    }
};

export default DerivedFields;
