import { generatePath } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FILE_STATUS, fileStatusByKey } from 'src/constants/FileStatus';

import { OVTPATHS } from '../../../constants/constants';
import * as filesApi from '../../../services/api/files';

export const createDownloadLinkAndClickIt = (
    href: string,
    fileName: string,
) => {
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const requestFileDownload = async (
    submissionId: string,
    instId: string,
    fileName: string,
    uploaded: string,
    enriched = false,
    isOvt = false,
    isSchemaError = false,
) => {
    const uploadedDate = DateTime.fromISO(uploaded, { zone: 'utc' });
    const fullPath = `${instId}/${uploadedDate.year}/${uploadedDate.month}/${uploadedDate.day}`;
    const csvFileName = `${fileName.substr(0, fileName.lastIndexOf('.'))}.csv`;
    const schemaErrorsFileName = `SchemaErrors_${submissionId}_${csvFileName}`;

    let key;
    if (isOvt) {
        if (isSchemaError) {
            key = await filesApi.ovtDownloadRequest(
                `${fullPath}/OVT/${schemaErrorsFileName}`,
            );
        } else {
            key = await filesApi.ovtDownloadRequest(
                `${fullPath}/OVT/${
                    enriched ? 'Enriched_' : ''
                }${submissionId}_${fileName}`,
            );
        }
    } else {
        key = await filesApi.downloadRequest(
            `${fullPath}/${
                enriched ? 'Enriched_' : ''
            }${submissionId}_${fileName}`,
        );
    }
    createDownloadLinkAndClickIt(
        key.data,
        isSchemaError
            ? schemaErrorsFileName
            : `${enriched ? 'Enriched_' : ''}${fileName}`,
    );
};
export const requestOvtFileDownload = async (
    submissionId: string,
    instId: string,
    fileName: string,
    uploaded: string,
    enriched = false,
) => {
    requestFileDownload(
        submissionId,
        instId,
        fileName,
        uploaded,
        enriched,
        true,
    );
};

export const requestOvtSchemaErrorsFileDownload = async (
    submissionId: string,
    instId: string,
    fileName: string,
    uploaded: string,
) => {
    requestFileDownload(
        submissionId,
        instId,
        fileName,
        uploaded,
        false,
        true,
        true,
    );
};

export const requestSignOffFormFileDownload = async (hash: string) => {
    const key = await filesApi.signOffFormDownloadRequest(hash);
    createDownloadLinkAndClickIt(key.data, `${hash}.pdf`);
};

export const requestAllQualityRuleFailuresDownload = async (
    submissionUuid: string,
) => {
    const { url } = await filesApi.ovtDownloadAllQualityRuleFailures(
        submissionUuid,
    );
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const requestQualityRuleFailuresDownload = async (uri: string) => {
    const link = document.createElement('a');
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
};

// Download a blob as a file with the given name
// https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js
export const requestBlobDownload = async (data: string, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    createDownloadLinkAndClickIt(url, fileName);
};

export const calculatePercentage = (
    numerator: number,
    denominator: number,
    precision = 2,
) => parseFloat(((numerator / denominator) * 100).toFixed(precision));

export function getSteps(collectionId: string, submissionId: string) {
    return [
        {
            id: 0,
            label: 'Upload',
            name: 'upload',
            path: generatePath(OVTPATHS.SUBMISSION, {
                collectionId,
                submissionId,
            }),
        },
        {
            id: 1,
            label: 'Processing',
            name: 'processing',
            path: generatePath(OVTPATHS.SUBMISSION, {
                collectionId,
                submissionId,
            }),
        },
        {
            id: 2,
            label: 'Quality assurance',
            name: 'reports',
            path: generatePath(OVTPATHS.REPORTS, {
                collectionId,
                submissionId,
            }),
        },
        {
            id: 3,
            label: 'Approval & sign-off',
            name: 'signoff',
            path: generatePath(OVTPATHS.SUBMISSION, {
                collectionId,
                submissionId,
            }),
        },
    ];
}

export const calculateEvents = (status: string) =>
    Object.keys(FILE_STATUS)
        .filter(
            state =>
                fileStatusByKey(state).order < fileStatusByKey(status).order,
        )
        .concat(status)
        .map(state => ({ status: state }));

export function renderTolerance(
    toleranceType: number,
    toleranceFrom: number,
    toleranceTo: number,
) {
    switch (toleranceType) {
        case 1:
            return toleranceFrom;
        case 2:
            return `${toleranceFrom}%`;
        case 3:
            return `${toleranceFrom}-${toleranceTo}`;
        default:
            return toleranceFrom;
    }
}

export function renderStatus(status: boolean | undefined) {
    switch (status) {
        case true:
            return 'Outside';
        case false:
            return 'Inside';
        default:
            return 'Outside';
    }
}

export const RiskLevel2 = '02';
export const RiskLevel1 = '01';
export const CountryCodeEngland = 'E';

export const getApprovalStates = (riskCode: string, countryCode: string) => {
    const analystApproval = {
        ...FILE_STATUS.HESA_ANALYST_APPROVAL,
        code: 'HESA_ANALYST_APPROVAL',
        name: FILE_STATUS.HESA_ANALYST_APPROVAL.label,
    };
    const hesaApproval = {
        ...FILE_STATUS.HESA_APPROVAL,
        code: 'HESA_APPROVAL',
        name: FILE_STATUS.HESA_APPROVAL.label,
    };
    const scApproval = {
        ...FILE_STATUS.SC_APPROVAL,
        code: 'SC_APPROVAL',
        name: FILE_STATUS.SC_APPROVAL.label,
    };

    // High Risk England
    if (riskCode === RiskLevel2 && countryCode === CountryCodeEngland) {
        return [analystApproval, hesaApproval, scApproval];
    }
    // High Risk - Welsh, Scottish, NI
    if (riskCode === RiskLevel2 && countryCode !== CountryCodeEngland) {
        return [analystApproval, hesaApproval];
    }
    // Standard - English
    if (riskCode === RiskLevel1 && countryCode === CountryCodeEngland) {
        return [hesaApproval, scApproval];
    }
    // Standard - Welsh, Scottish, NI
    if (riskCode === RiskLevel1 && countryCode !== CountryCodeEngland) {
        return [hesaApproval];
    }
};

export const issueStatuses = {
    PENDING: 'Pending',
    CREATED: 'Created',
    FAILED: 'Failed to create',
};
