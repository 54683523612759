import { useMemo } from 'react';
import useProviderStatuses from 'queries/submissions/useProviderStatuses';
import useSubmissionStates from 'queries/submissions/useSubmissionStates';
import { useUserContext } from 'src/components';

import {
    addActionStates,
    addAwaitingParty,
    addNextApprovalState,
    addTotalSteps,
    getStateOfQueries,
    pipe,
    queryStates,
    transformToAwaitingParties,
    transformToSteps,
} from '../utils';

import { SubmissionHydrated } from './utils';

export const useProviderSubmissions = ({
    collectionReference,
}: {
    collectionReference: string;
}) => {
    const providerStatuses = useProviderStatuses({ collectionReference });
    const { isHesa, activeOrganisation, isStatutoryCustomer } =
        useUserContext();
    const submissionStates = useSubmissionStates();

    const status = useMemo(
        () => getStateOfQueries([providerStatuses, submissionStates]),
        [providerStatuses, submissionStates],
    );

    const submissions = useMemo(() => {
        return status === queryStates.LOADED && submissionStates.data
            ? (providerStatuses.data?.map(
                  pipe(
                      addTotalSteps(transformToSteps(submissionStates.data)),
                      addAwaitingParty(
                          transformToAwaitingParties(submissionStates.data),
                      ),
                      addNextApprovalState,
                      addActionStates(
                          isHesa,
                          activeOrganisation,
                          isStatutoryCustomer,
                      ),
                  ),
              ) as SubmissionHydrated[])
            : [];
    }, [
        status,
        providerStatuses.data,
        submissionStates.data,
        isHesa,
        activeOrganisation,
        isStatutoryCustomer,
    ]);

    const states = useMemo(() => {
        return status === queryStates.LOADED ? submissionStates.data : [];
    }, [submissionStates, status]);

    return {
        status,
        submissions,
        submissionStates: states,
    };
};
