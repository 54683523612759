import { useQuery } from '@tanstack/react-query';
import { GetSubmissionValidationDetailsArgs } from 'services/api/submissions/types';

import { getUseValidationDetailsOptions } from './options';

interface UseValidationDetailsArgs extends GetSubmissionValidationDetailsArgs {
    enabled?: boolean;
}

export default function useValidationDetails({
    enabled = true,
    ...args
}: UseValidationDetailsArgs) {
    const options = getUseValidationDetailsOptions(args);
    return useQuery({
        ...options,
        enabled,
    });
}
