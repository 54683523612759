import { useMutation, useQueryClient } from '@tanstack/react-query';
import { schemas as schemasApi } from 'services/api';

import { Schema, UpdateSchemaStateArgs } from './types';

export default function useUpdateSchemaState() {
    const queryClient = useQueryClient();

    const { mutate, ...mutation } = useMutation({
        mutationFn: (args: UpdateSchemaStateArgs) =>
            schemasApi.updateState(args),
        onSettled: (mutationResponse, _error) => {
            queryClient.setQueryData(
                ['schemas', ''],
                (previousData: { schemas: Schema[] }) => {
                    return {
                        ...previousData,
                        schemas: previousData.schemas.map((schema: Schema) => {
                            if (schema.id === mutationResponse.id) {
                                const oldSchema = schema;
                                const newSchema = {
                                    ...oldSchema,
                                    ...mutationResponse,
                                };

                                return newSchema;
                            }
                            return schema;
                        }),
                    };
                },
            );
            queryClient.invalidateQueries({
                queryKey: ['schemas', ''],
            });
        },
    });

    return { mutate, ...mutation };
}
