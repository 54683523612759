import { Typography } from '@mui/material';
import { PageTitle } from 'components';

export const Error = () => {
    return (
        <div role="alert">
            <PageTitle title="An error has occurred" />
            <Typography>Oops - something went wrong.</Typography>
        </div>
    );
};

Error.displayName = 'Error';
