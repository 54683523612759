import React, { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import TabPanel from 'components/Tabs/TabPanel';
import { SpecificationInterface } from 'queries/specifications/types';
import useDerivedFields from 'queries/specifications/useDerivedFields';
import useValidations from 'queries/specifications/useValidations';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import SpecTabPanelContext from '../context/SpecTabPanelContext';
import DerivedFields from '../DerivedFields/DerivedFields';
import Validations from '../Validations/Validations';

const a11yprops = (name: string) => {
    return {
        id: `wrapped-tab-${name}`,
        'aria-controls': `wrapped-tabpanel-${name}`,
    };
};

interface SpecRulesProps {
    spec: SpecificationInterface;
}

const SpecRules = ({ spec }: SpecRulesProps) => {
    const [tabValue, setTabValue] = useState('validations');
    const [, setSearchParams] = useSearchParams();

    const handleTabChange = (
        _event: React.SyntheticEvent,
        newTabValue: string,
    ) => {
        setTabValue(newTabValue);
        setSearchParams({});
    };

    // Call the validations API, and get all the validations for the spec, along
    // with the total number of validations.
    const {
        validationParams,
        setValidationPage,
        setValidationRowsPerPage,
        setValidationFilterTerm,
    } = useContext(SpecTabPanelContext);

    const { data: validationsData, status: validationsStatus } = useValidations(
        {
            id: spec.id,
            offset: validationParams.page,
            limit: validationParams.rowsPerPage,
            filter: validationParams.filter,
        },
    );

    const handleChangeRowsPerPageValidation = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setValidationRowsPerPage(+event.target.value);
    };

    const handleChangePageValidation = (
        _event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setValidationPage(newPage);
    };

    const handleFilterValidation = ({ filterTerm }: { filterTerm: string }) =>
        setValidationFilterTerm(filterTerm);

    const handleTryAgain = () => {
        // Refresh the page so that the data is fetched again
        window.location.reload();
    };

    // Call the derived fields API, and get all the derived fields for the spec,
    // along with the total number of derived fields.
    const {
        derivedFieldParams,
        setDerivedFieldPage,
        setDerivedFieldRowsPerPage,
        setDerivedFieldsFilterTerm,
    } = useContext(SpecTabPanelContext);

    const { data: derivedFieldsData, status: derivedFieldsStatus } =
        useDerivedFields({
            id: spec.id,
            offset: derivedFieldParams.page,
            limit: derivedFieldParams.rowsPerPage,
            filter: derivedFieldParams.filter,
        });

    const handleChangeRowsPerPageDerivedField = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        setDerivedFieldRowsPerPage(+event.target.value);
    };

    const handleChangePageDerivedField = (
        _event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ): void => {
        setDerivedFieldPage(newPage);
    };

    const handleFilterDerivedField = ({ filterTerm }: { filterTerm: string }) =>
        setDerivedFieldsFilterTerm(filterTerm);

    return spec ? (
        <>
            <Box mt={3}>
                <Paper square>
                    <Tabs
                        variant="fullWidth"
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                    >
                        <Tab
                            value="validations"
                            data-test-id={formatTestId(
                                'openTab',
                                'validations',
                            )}
                            label={
                                validationsData?.pagingMetadata?.total
                                    ? `Validation Rules (${validationsData.pagingMetadata.total})`
                                    : `Validation Rules`
                            }
                            wrapped
                            {...a11yprops('validations')}
                        />
                        <Tab
                            value="derived-fields"
                            data-test-id={formatTestId(
                                'openTab',
                                'derivedFields',
                            )}
                            label={
                                derivedFieldsData?.pagingMetadata
                                    ?.totalDerivedFields
                                    ? `Derived Fields (${derivedFieldsData.pagingMetadata.totalDerivedFields})`
                                    : `Derived Fields`
                            }
                            wrapped
                            {...a11yprops('derived-fields')}
                        />
                    </Tabs>
                </Paper>
            </Box>
            <TabPanel value={tabValue} index="validations">
                {validationsData && (
                    <Validations
                        spec={spec}
                        data={validationsData}
                        handleChangeRowsPerPage={
                            handleChangeRowsPerPageValidation
                        }
                        handleChangePage={handleChangePageValidation}
                        handleFilter={handleFilterValidation}
                        handleTryAgain={handleTryAgain}
                        validationParams={validationParams}
                        status={validationsStatus}
                    />
                )}
            </TabPanel>
            <TabPanel value={tabValue} index="derived-fields">
                <DerivedFields
                    data={derivedFieldsData}
                    handleChangeRowsPerPage={
                        handleChangeRowsPerPageDerivedField
                    }
                    handleChangePage={handleChangePageDerivedField}
                    handleFilter={handleFilterDerivedField}
                    handleTryAgain={handleTryAgain}
                    derivedFieldParams={derivedFieldParams}
                    status={derivedFieldsStatus}
                />
            </TabPanel>
        </>
    ) : null;
};

export default SpecRules;
