import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress } from '@mui/material';
import { useUserContext } from 'components';
import Accordion from 'components/Accordion/Accordion';
import AccordionGroup from 'components/AccordionGroup/AccordionGroup';
import CollectionsActivityLog from 'components/ActivityLog/CollectionsActivityLog/CollectionsActivityLog';
import { DateTime } from 'luxon';
import StepSummary from 'pages/Monitoring/StepSummary/StepSummary';
import useProviderStatuses from 'queries/submissions/useProviderStatuses';
import { PageTitle } from 'src/components/PageTitle/PageTitle';
import { ACCORDION_IDS } from 'src/constants/constants';
import useCollection from 'src/queries/collections/useCollection';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import CollectionSummary from '../CollectionSummary/CollectionSummary';
import { OvtSubmissionSummary } from '../OvtSubmissionSummary/OvtSubmissionSummary';
import { SubmissionSummary } from '../SubmissionSummary/SubmissionSummary';

import styles from './collectionPage.module.scss';

const formatDate = (date: string) =>
    DateTime.fromISO(date).toFormat('dd/LL/yyyy');

const Loading = () => {
    return (
        <Box className={styles.box}>
            <CircularProgress />
        </Box>
    );
};

export const CollectionPage = () => {
    const navigate = useNavigate();
    const { isHesa } = useUserContext();
    const { reference } = useParams() as { reference: string };
    const { data: collection, status } = useCollection({ reference });

    const { data: statuses } = useProviderStatuses({
        collectionReference: reference,
    });

    const providers = statuses?.map(s => s.provider) || [];

    const renderContent = () => {
        switch (status) {
            case 'pending':
                return <Loading />;

            case 'success':
                return (
                    <>
                        {collection && (
                            <CollectionSummary
                                referencePeriodStart={formatDate(
                                    collection.referencePeriodStart,
                                )}
                                referencePeriodEnd={formatDate(
                                    collection.referencePeriodEnd,
                                )}
                                status={collection.state.name}
                            />
                        )}
                        <AccordionGroup>
                            <Accordion
                                id={ACCORDION_IDS.MONITORING.STEP_SUMMARY}
                                title="Step Summary"
                                dataTestId={formatTestId(
                                    'open accordion',
                                    'step summary',
                                )}
                            >
                                <StepSummary collectionReference={reference} />
                            </Accordion>

                            <Accordion
                                id={ACCORDION_IDS.MONITORING.SUBMISSION_SUMMARY}
                                title="Submission Summary"
                                dataTestId={formatTestId(
                                    'open accordion',
                                    'submission summary',
                                )}
                                isOpenByDefault
                            >
                                <SubmissionSummary
                                    collectionReference={reference}
                                />
                            </Accordion>
                            {isHesa ? (
                                <Accordion
                                    id={
                                        ACCORDION_IDS.MONITORING
                                            .SUBMISSION_SUMMARY_OVT
                                    }
                                    title="Submission Summary OVT"
                                    dataTestId={formatTestId(
                                        'open accordion',
                                        'submission summary ovt',
                                    )}
                                >
                                    <OvtSubmissionSummary
                                        collectionReference={reference}
                                    />
                                </Accordion>
                            ) : (
                                <></>
                            )}
                            {isHesa && (
                                <Accordion
                                    id={ACCORDION_IDS.MONITORING.ACTIVITY_LOG}
                                    title="Activity log"
                                    dataTestId={formatTestId(
                                        'open accordion',
                                        'activity log',
                                    )}
                                >
                                    <CollectionsActivityLog
                                        providers={providers}
                                    />
                                </Accordion>
                            )}
                        </AccordionGroup>

                        <div className={styles.buttons}>
                            <Button
                                data-test-id="back"
                                disabled={false}
                                disableRipple={true}
                                variant="contained"
                                onClick={() => navigate(-1)}
                            >
                                Back
                            </Button>
                        </div>
                    </>
                );

            default:
                return <></>;
        }
    };

    return (
        <>
            <PageTitle
                title={collection ? `${collection.name}` : `Monitoring`}
            />
            {renderContent()}
        </>
    );
};

export default CollectionPage;
