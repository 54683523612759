import React, { ReactNode } from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import TablePagination from '@mui/material/TablePagination/TablePagination';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import styles from './hdpTable.module.scss';

export type Header<T> = {
    label: string;
    id: keyof T;
    isSortable?: boolean;
    alignment?: 'left' | 'centre';
};

export interface HDPTableProps<T extends object> {
    headers: Header<T>[];
    data: T[];
    onClickSort: (id: keyof T) => void;
    pagination?: {
        currentPage: number;
        totalRows: number;
        setLimit: (limit: number) => void;
        setOffset: (offset: number) => void;
        offset: number;
        limit: number;
    };
    sorting: {
        key: keyof T;
        direction: 'asc' | 'desc';
    };
    testId?: string;
}
/*
 * Table component to be used in HDP.
 * This table component is purely for display purposes.
 *   - It does not handle any data fetching or manipulation.
 *   - It does not handle any state.
 *   - The table headers are passed in as an array of objects.
 *   - Data to display needs to be transformed to match the header keys before being passed in.
 */
function HDPTable<T extends object>({
    headers,
    data,
    onClickSort,
    sorting,
    pagination,
    testId,
}: HDPTableProps<T>) {
    const getIndexOfHeader = (key: keyof T) => {
        return headers.findIndex(header => header.id === key);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (pagination === undefined) return;
        const rows = +event.target.value;
        pagination.setLimit(rows);
        pagination.setOffset(0);
    };

    const handleChangePage = (
        _event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        if (pagination === undefined) return;
        pagination.setOffset(newPage);
    };

    return (
        <table
            className={styles.table}
            data-test-id={testId ? formatTestId('table', testId) : null}
        >
            <thead className={styles.header}>
                <tr>
                    {headers.map(({ label, id, isSortable }) => (
                        <th
                            key={id as string}
                            onClick={() => {
                                isSortable ? onClickSort(id) : null;
                            }}
                            data-test-id={
                                isSortable && testId
                                    ? formatTestId(`sort ${testId}`, label)
                                    : null
                            }
                            className={isSortable ? styles.sortableHeader : ''}
                        >
                            <div
                                className={
                                    isSortable ? styles.headingAndArrows : ''
                                }
                            >
                                <p className={styles.headerText}>{label}</p>
                                {isSortable && (
                                    <div className={styles.sortArrows}>
                                        <KeyboardArrowUpRoundedIcon
                                            className={
                                                sorting.key === id &&
                                                sorting.direction === 'desc'
                                                    ? styles.active
                                                    : ''
                                            }
                                        />

                                        <KeyboardArrowDownRoundedIcon
                                            className={
                                                sorting.key === id &&
                                                sorting.direction === 'asc'
                                                    ? styles.active
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr key={index} className={styles.dataRow}>
                        {Object.keys(row).map(key => (
                            <td
                                key={key}
                                className={`${
                                    styles[
                                        headers[
                                            getIndexOfHeader(key as keyof T)
                                        ]?.alignment || ''
                                    ]
                                }`}
                            >
                                {row[key as keyof T] as ReactNode}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            <tfoot>
                {pagination !== undefined && (
                    <tr>
                        <td colSpan={headers.length}>
                            {/* Replace this with our own dropdowns and buttons instead of the MUI component at some point?.*/}
                            <div>
                                <TablePagination
                                    rowsPerPageOptions={[
                                        { label: '10', value: 10 },
                                        { label: '25', value: 25 },
                                        { label: '50', value: 50 },
                                        { label: '100', value: 100 },
                                        { label: 'All', value: -1 },
                                    ]}
                                    component="div"
                                    count={pagination.totalRows}
                                    rowsPerPage={pagination.limit}
                                    page={pagination.offset}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                />
                            </div>
                        </td>
                    </tr>
                )}
            </tfoot>
        </table>
    );
}

export default HDPTable;
