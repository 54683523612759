import React from 'react';

type TestIdProps = {
    'data-test-id'?: string;
};

type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

type WithTestIdProps<P> = P & {
    testIdProps?: DeepPartial<{
        [K in keyof P]: TestIdProps;
    }>;
};

/**
 * Wrapper component that adds data-test-id to the wrapped component. It is used to add data-test-id to MUI components
 * without causing type errors.
 * @param {React.ComponentType<P>} WrappedComponent
 * @returns {React.Component}
 */
function WithTestId<P extends object>(
    WrappedComponent: React.ComponentType<P>,
) {
    return function TestIdWrapper(props: WithTestIdProps<P>) {
        const { testIdProps, ...rest } = props;
        const newProps = { ...rest } as P;

        if (testIdProps) {
            Object.entries(testIdProps).forEach(([key, value]) => {
                if (
                    value &&
                    typeof value === 'object' &&
                    'data-test-id' in value
                ) {
                    (newProps as P)[key as keyof P] = {
                        ...(newProps as P)[key as keyof P],
                        'data-test-id': value['data-test-id'],
                    };
                }
            });
        }

        return <WrappedComponent {...newProps} />;
    };
}

export default WithTestId;
