import { HTMLAttributes } from 'react';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
    AutocompleteValue,
    Checkbox,
    UseAutocompleteRenderedOption,
} from '@mui/material';

import { AutocompleteOption } from '../AutocompleteDropdown';

import styles from './autocompleteDropdownItem.module.scss';

const checkboxIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

interface AutocompleteDropdownItemProps<Multiple extends boolean | undefined> {
    option: AutocompleteOption;
    index: number;
    getOptionProps: (
        renderedOption: UseAutocompleteRenderedOption<string>,
    ) => HTMLAttributes<HTMLLIElement> & {
        key: string | number;
    };
    value?: AutocompleteValue<string | number, Multiple, false, false>;
}

export default function AutocompleteDropdownItem<
    Multiple extends boolean | undefined,
>({
    option,
    index,
    getOptionProps,
    value,
}: AutocompleteDropdownItemProps<Multiple>) {
    const { key, ...optionProps } = getOptionProps({
        option: option.value.toString(),
        index,
    });

    let isSelected: boolean;

    if (Array.isArray(value)) {
        isSelected = value.findIndex(v => v === option.value) !== -1;
    } else {
        isSelected = value === option.value;
    }

    return (
        <li
            key={key}
            className={styles.item}
            {...optionProps}
            aria-selected={isSelected}
        >
            <Checkbox
                icon={checkboxIcon}
                checkedIcon={checkedIcon}
                checked={isSelected}
                disableFocusRipple
                disableRipple
            />
            {option.label}
        </li>
    );
}
