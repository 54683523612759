import { useUserContext } from 'components';
import { STREAMS } from 'constants/constants';
import { IdsStream } from 'types/auth';

import { Selector } from '../Selector';
import { SelectorOption } from '../Selector/Selector';

export const getStreamInformationFromIdsStream = (stream: IdsStream) => {
    return STREAMS.find(s => s.id === stream.id);
};

export const StreamSelector = () => {
    const { activeStream, streams, changeStream } = useUserContext();

    if (!streams || !activeStream) {
        return null;
    }

    const options = streams
        .map(getStreamInformationFromIdsStream)
        .map(stream => {
            if (stream) {
                return {
                    value: stream.id,
                    label: stream.name,
                    disabled: !stream.available,
                };
            }
        })
        .filter(stream => !!stream) as SelectorOption[];

    return (
        <Selector
            heading={'Stream:'}
            options={options}
            value={activeStream.id}
            onChange={changeStream}
        />
    );
};

StreamSelector.displayName = 'StreamSelector';
