import React, { useCallback } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Typography,
} from '@mui/material';
import {
    QueryObserverResult,
    RefetchOptions,
    useQuery,
} from '@tanstack/react-query';
import { requestBlobDownload } from 'pages/Collections/OnlineValidation/utils';
import { SubmissionOutletContextValue } from 'pages/Collections/Submission/types';
import useRegulator from 'queries/regulator/useRegulator';
import {
    getValidationDetails,
    getValidationDetailsCsv,
    getValidationDetailsUri,
} from 'services/api/submissions';
import QualityRule from 'src/components/QualityRule/QualityRule';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';

import { requestQualityRuleFailuresDownload } from '../../../utils';

interface QualityRuleErrorProps {
    rule: string;
    refetch: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<unknown, Error>>;
    isFetching: boolean;
}

const QualityRuleError = ({
    rule = '',
    refetch,
    isFetching,
}: QualityRuleErrorProps) => {
    // TODO: This is present for submission quality rule, but not for the other quality rules?
    const navigate = useNavigate();

    const goBack = useCallback(() => {
        navigate('../', { relative: 'route' });
    }, [navigate]);

    return (
        <Paper className="QualityRule__root">
            <div className="QualityRule__title-wrapper">
                <h2 className="QualityRule__id-link margin--bottom-0-5">
                    {rule}
                </h2>
            </div>

            <Box
                display={'flex'}
                flexDirection={'column'}
                p={5}
                alignItems={'center'}
            >
                {isFetching ? (
                    <CircularProgress aria-label="Circular loading animation" />
                ) : (
                    <>
                        <Typography
                            variant={'body1'}
                            paragraph
                            align={'center'}
                        >
                            No data available for this submission
                        </Typography>

                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={2}
                            justifyContent="center"
                        >
                            <Button
                                variant={'outlined'}
                                size={'small'}
                                onClick={() => refetch()}
                            >
                                Try again
                            </Button>
                            <Button
                                variant={'outlined'}
                                size={'small'}
                                onClick={goBack}
                            >
                                Back
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Paper>
    );
};

const SubmissionQualityRule = () => {
    const [isDownloading, setIsDownloading] = React.useState(false);
    const {
        rule = '',
        submissionId = '',
        regulatorCode = '',
    } = useParams<{
        rule: string;
        submissionId: string;
        regulatorCode: string;
    }>();

    const { data: submission } =
        useOutletContext<SubmissionOutletContextValue>();

    const DEFAULT_TABLE_PARAMETERS = {
        limit: 10,
        offset: 0,
    };

    const tableId = TABLE_PARAMS_IDS.REPORTS_QUALITY_RULES;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { limit, offset } = tableParams;

    const { setLimit, setOffset } = tableParamsMethods;

    const { instId } = submission.provider;

    const { data, isFetching, refetch, isError } = useQuery({
        queryKey: ['validations', submissionId, instId, rule, offset, limit],
        queryFn: () =>
            getValidationDetails({
                submissionId,
                validationId: rule,
                instId,
                regulatorCode,
                offset,
                limit,
            }),
    });

    function handleChangeRowsPerPage(event: {
        target: { value: string | number };
    }) {
        setLimit(+event.target.value);
        setOffset(0);
    }

    function handleChangePage(_: unknown, newPage: number) {
        setOffset(newPage);
    }

    // All records will have the same regulator code, so just use the first record.
    const regulatorCodeForFirstRecord = data?.records[0]?.RegulatorCode;

    const { data: regulator } = useRegulator(regulatorCodeForFirstRecord);

    const validation = data?.validation;
    const pageData = data?.pagingMetadata;
    const results = data?.records;

    const handleClickDownloadCsv = async () => {
        if (submissionId) {
            setIsDownloading(true);
            if (data?.pagingMetadata.totalResultsCount > 1000) {
                const uri = await getValidationDetailsUri({
                    submissionId,
                    validationId: rule,
                    instId,
                    regulatorCode,
                });

                await requestQualityRuleFailuresDownload(uri);
            } else {
                const csv = await getValidationDetailsCsv({
                    submissionId,
                    validationId: rule,
                    instId,
                    regulatorCode,
                });

                await requestBlobDownload(
                    csv,
                    `QualityRuleReport.${submissionId.toLowerCase()}.${rule}.${new Date().toISOString()}.csv`,
                );
            }
            setIsDownloading(false);
        }
    };

    if (
        isError ||
        !data?.records?.length ||
        !data?.pagingMetadata ||
        data?.records?.validation
    ) {
        return (
            <QualityRuleError
                rule={rule}
                refetch={refetch}
                isFetching={isFetching}
            />
        );
    }

    return (
        <QualityRule
            isDownloading={isDownloading}
            rule={rule}
            regulator={regulator}
            results={results}
            validation={validation}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleClickDownloadCsv={handleClickDownloadCsv}
            pageData={pageData}
            offset={offset}
            limit={limit}
        />
    );
};

export default SubmissionQualityRule;
