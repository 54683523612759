import { createContext, ReactNode, useContext, useState } from 'react';
import { useEffect } from 'react';
import {
    UIMatch,
    useLocation,
    useMatches,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useCommitAllowedStatus from 'queries/submissions/useCommitAllowedStatus';
import { submissions as submissionsApi } from 'services/api';
import { FILE_STATUS, fileStatusById } from 'src/constants/FileStatus';
import { Submission } from 'types/submission';
import { HdpMessage } from 'types/websocket';

import { inferStepLabelFromRoute } from '../../utils';

import { StepName, StepObject, StepperContextValue } from './types';

export const StepperContext = createContext<StepperContextValue | null>(null);

export const useStepper = () => {
    const context = useContext(StepperContext);
    if (!context) {
        throw new Error('useStepper must be used within a StepperProvider');
    }
    return context;
};

interface StepperProviderProps {
    submissionId: string;
    submissionData: Submission;
    steps: StepName[];
    paths: Record<string, string>;
    isStatutoryCustomer: boolean;
    latestMessage?: HdpMessage | null;
    children: ReactNode;
    ovt?: boolean;
}

const StepperProvider = ({
    submissionId,
    submissionData,
    steps,
    paths,
    ovt = false,
    isStatutoryCustomer,
    latestMessage,
    children,
}: StepperProviderProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeStep, setActiveStep] = useState({
        label: '',
    });

    const { stateHistory } = submissionData;
    const currentState = submissionData.status;

    const matches = useMatches() as UIMatch<unknown, { crumb: StepName }>[];
    const params = useParams();
    const userRequestedStepLabel = inferStepLabelFromRoute(matches, steps);

    const [stepData, setStepData] = useState<StepObject[]>(
        steps?.map(step => ({
            label: step,
        })),
    );

    // Additional Reports & Credibility Reports routes use :reportCode
    // Quality Reports routes use :rule
    const isUserViewingReport = !!params?.reportCode || !!params?.rule;

    const isEveryStepComplete = stepData.every(
        step => step?.isComplete === true,
    );

    const isEveryStepValidated = stepData.every(
        step => 'isComplete' in step && 'isError' in step && 'isActive' in step,
    );

    const canNavigateToStep = (stepLabel: string) => {
        const step = stepData.find(step => step.label === stepLabel);
        return step?.isComplete || step?.isError || step?.isActive;
    };

    const navigateToStep = (step: string, replace: boolean = false) => {
        if (!step) return;

        const path = paths[step] as string;
        if (path !== location.pathname) {
            navigate(path, { replace });
        }
    };

    let targetStep;
    if (!isEveryStepValidated || isUserViewingReport) {
        targetStep = undefined;
    } else {
        // If the user has requested a specific step (e.g by refreshing the page), navigate to that step
        if (canNavigateToStep(userRequestedStepLabel ?? '')) {
            targetStep = stepData.find(
                step => step.label === userRequestedStepLabel,
            );
        } else {
            // Redirect to sign-off step if all steps are complete
            if (isEveryStepComplete && !isStatutoryCustomer) {
                targetStep = stepData.at(-1);
            } else {
                const incompleteStepIndex = stepData.findLastIndex(
                    step => step?.isError || step?.isActive,
                );

                const qualityAssuranceStepIndex = stepData.findIndex(
                    step => step.label === 'Quality Assurance',
                );

                if (
                    isStatutoryCustomer &&
                    incompleteStepIndex > qualityAssuranceStepIndex
                ) {
                    targetStep = stepData[qualityAssuranceStepIndex];
                } else {
                    targetStep = stepData[incompleteStepIndex];
                }
            }
        }
    }

    useEffect(() => {
        if (!targetStep) return;

        if (targetStep.label !== activeStep.label) {
            const shouldReplaceHistoryEntry = !canNavigateToStep(
                userRequestedStepLabel ?? '',
            );
            setActiveStep(targetStep);
            navigateToStep(targetStep.label, shouldReplaceHistoryEntry);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, navigateToStep, targetStep, canNavigateToStep]);

    const updateStep = (index: number, newStep: StepObject) => {
        const newSteps = [...stepData];
        newSteps[index] = newStep;
        setStepData(newSteps);
    };

    const shouldFetchCommitStatus = (() => {
        if (!stateHistory?.length) return false;

        const currentStateBeyondQA =
            fileStatusById(currentState?.id)?.order >
            FILE_STATUS.QUALITY_PROCESSING_COMPLETE.order;

        return (
            stateHistory?.some(
                state =>
                    state.StatusId ===
                    FILE_STATUS.QUALITY_PROCESSING_COMPLETE.id,
            ) ||
            (submissionData?.copiedFrom && currentStateBeyondQA)
        );
    })();

    const commitStatusQuery = useCommitAllowedStatus({
        submissionId,
        enabled:
            Boolean(!ovt) &&
            Boolean(submissionId) &&
            Boolean(shouldFetchCommitStatus),
    });

    const ovtQuery = useQuery({
        queryKey: ['ovtFailures', submissionId],
        queryFn: () =>
            submissionsApi.getOvtFailureCounts({
                submissionId,
                instId: submissionData?.provider?.instId,
            }),
        enabled:
            Boolean(ovt) &&
            Boolean(submissionId) &&
            Boolean(shouldFetchCommitStatus),
    });

    const contextValue = {
        currentState,
        stateHistory,
        commitStatusQuery,
        ovtQuery,
        paths,
        updateStep,
        steps: stepData,
        ovt,
        submissionData,
        navigateToStep,
        isSubmissionSignedOff: currentState?.id === FILE_STATUS.SIGNED_OFF.id,
        isBurpSubmission: Boolean(submissionData?.copiedFrom),
        activeStep,
        latestMessage,
    };

    return (
        <StepperContext.Provider value={contextValue}>
            {children}
        </StepperContext.Provider>
    );
};

export default StepperProvider;
