// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from '@emotion/styled';
import { colors, sizes } from 'src/styles/config/branding.config';

export const HDPAccordionBase = styled.div(
    {
        backgroundColor: 'white',
        borderRadius: '0.25rem',
        width: '100%',
        transition: 'margin 282ms ease-in-out 0ms',
    },
    props => ({
        borderColor:
            props.borderColor || props.expanded
                ? colors.shipCove
                : colors.geyser,
        borderWidth: props.borderWidth || props.expanded ? '2px' : '1px',
        borderRadius: props.square && '0',
        borderStyle: (props.noBorder && 'none') || 'solid',
        border: props.noBorder && 'none',
        fontSize: sizes[props.size] || sizes.lg,
        padding: `${sizes[props.size] || sizes.sm} 0`,
        backgroundColor: props.background,
        boxShadow:
            props.elevated &&
            `rgb(0 0 0 / 20%) 0rem 0.2rem 0.1rem -0.1rem, 
            rgb(0 0 0 / 14%) 0rem 0.1rem 0.1rem 0rem, 
            rgb(0 0 0 / 12%) 0rem 0.1rem 0.3rem 0rem`,
    }),
);

export const HDPAccordionSummaryBase = styled.div(
    {
        display: 'flex',
        backgroundColor: 'inherit',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        color: 'currentcolor',
    },
    props => ({
        fontSize: sizes[props.size] || sizes.lg,
        fontWeight: props.fontWeight || 'bold',
        color: props.color || colors.accessibility,
    }),
);

export const HDPAccordionSummaryButtonBase = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    height: 100%;
    cursor: pointer;
    flex: 2 1 auto;
    gap: 0.25rem;
    padding: 0.25rem 0;
    text-align: start;
`;

export const HDPAccordionExpandIconBase = styled.div`
    color: currentcolor;
    transform: ${props =>
        !props.disabled ? 'rotate(-90deg)' : 'rotate(0deg)'};
    ${props => props.expanded && 'transform: rotate(0deg)'};
    transition: transform 185ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
    order: ${props => props.placement === 'end' && '5'};
    margin-left: ${props => props.placement === 'end' && 'auto'};

    > svg {
        fill: currentcolor;
        height: ${props => sizes[props.size] || sizes.xxl};
        width: ${props => sizes[props.size] || sizes.xxl};
    }

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }
`;

export const HDPAccordionActionsBase = styled.div`
    display: flex;
    margin-right: 1rem;
    color: ${colors.accessibility};
`;

export const HDPAccordionDetailsWrapper = styled.div`
    background-color: inherit;
    max-height: 0;
    overflow: ${props => (props.expanded ? 'none' : 'hidden')};
    max-height: ${props => (props.expanded ? 'none' : '0')};
`;

export const HDPAccordionDetailsBase = styled.div`
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    flex-direction: column;
    padding: 1rem 1rem;

    font-weight: 400;
    font-size: 1rem;
`;

export const HDPAccordionGroupBase = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${props => (props.spread && '1rem') || '0'};
    padding: ${props => props.spread && '0.5rem 0'};

    ${props =>
        !props.spread &&
        `        
            > div {
                :has([aria-expanded='true']) {
                    border: none;
                }

                border-bottom: 1px solid gray;
            }
    `}

    *:has([aria-expanded='true']) {
        margin-top: ${props => !props.spread && '1rem'};
    }

    *:has([aria-expanded='true']) + * {
        margin-top: ${props => !props.spread && '1rem'};
    }
`;
