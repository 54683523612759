import {
    FILE_STATUS,
    fileStatusById,
    fileStatusByKey,
    STATUS_ICONS,
} from 'src/constants/FileStatus';

import type { DisplayStates, DisplayStateType } from './types';

export const checkIfErrorInStates = (
    states: string[],
    submissionStateId: number,
) => states.some(state => fileStatusByKey(state).id === submissionStateId);

export const checkIfWaitingInStates = (
    states: string[],
    submissionStateId: number,
) =>
    states.some(
        state =>
            fileStatusByKey(state).order >
                fileStatusById(submissionStateId).order ||
            fileStatusByKey(state).order ===
                fileStatusById(submissionStateId).order,
    );

export const checkIfPassedInStates = (
    states: string[],
    submissionStateId: number,
) =>
    states.some(
        state =>
            fileStatusByKey(state).order <=
            fileStatusById(submissionStateId).order,
    );

export const getStateForStep = (
    displayStates: DisplayStates,
    submissionStateId: number,
): { displayState: DisplayStateType; label: string } => {
    if (checkIfErrorInStates(displayStates.error.states, submissionStateId)) {
        return {
            displayState: 'error',
            label: displayStates.error.label,
        };
    }

    if (checkIfPassedInStates(displayStates.passed.states, submissionStateId)) {
        return {
            displayState: 'passed',
            label: displayStates.passed.label,
        };
    }

    if (
        checkIfWaitingInStates(displayStates.waiting.states, submissionStateId)
    ) {
        return {
            displayState: 'waiting',
            label: displayStates.waiting.label,
        };
    }

    // if none found, return error
    return {
        displayState: 'error',
        label: displayStates.error.label,
    };
};

const displayStateIcons = {
    error: STATUS_ICONS.STATE_FAILED,
    passed: STATUS_ICONS.STATE_CAN_PROGRESS,
    waiting: STATUS_ICONS.STATE_IN_PROGRESS,
} as const;

export const getIconForDisplayState = (displayState: DisplayStateType) =>
    displayStateIcons[displayState];

export const checkIfSchemaCheckFailed = (statusId: number) => {
    return (
        statusId === FILE_STATUS.SCHEMA_CHECK_ERROR.id ||
        statusId === FILE_STATUS.SCHEMA_CHECK_FAILED.id
    );
};
