import { generatePath, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { OVTPATHS, PATHS } from 'src/constants/constants';
import { useStepper } from 'src/pages/Collections/Submission/Steps/Stepper/StepperContext';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { v4 as uuid } from 'uuid';

import { RestrictAccess, useUserContext } from '..';

import styles from './continueBackUploadNew.module.scss';

type ContinueBackUploadNewProps = {
    isContinuePossible: boolean;
    collectionId: string;
    collectionReference?: string;
    instId: string;
    isOvt: boolean;
    nextStepName: string;
    previousStepName: string;
    uploadPermissionsList: string[];
    isResubmissionAllowed: boolean;
};

const getUploadPath = (isOvt: boolean, isAdmin: boolean) => {
    switch (isOvt) {
        case true:
            switch (isAdmin) {
                case true:
                    return OVTPATHS.ADMIN_UPLOAD;
                case false:
                    return OVTPATHS.UPLOAD;
            }
            break;

        case false:
            switch (isAdmin) {
                case true:
                    return PATHS.ADMIN_UPLOAD;
                case false:
                    return PATHS.UPLOAD;
            }
            break;
    }
};

/*
    Component for the Continue, Back and Upload new file buttons on the submission steps. 
    We currently copy-paste these buttons across multiple steps, with various differences in how the button
    props are set. This component is intended to gather up all the logic for these buttons based on the 
    submission state and permissions, and reduce the amount of duplication of logic across the steps,
    hopefully simplifying the whole thing.
 */
const ContinueBackUploadNew = ({
    isContinuePossible,
    collectionId,
    instId,
    collectionReference,
    isOvt,
    nextStepName,
    previousStepName,
    uploadPermissionsList,
    isResubmissionAllowed,
}: ContinueBackUploadNewProps) => {
    const { navigateToStep } = useStepper();
    const { isAdmin } = useUserContext();

    const uploadPath = getUploadPath(isOvt, isAdmin);
    const newSubmissionRoute = generatePath(uploadPath, {
        collectionId: collectionId,
        reference: collectionReference ? collectionReference : '',
        submissionId: uuid().toUpperCase(),
        instId,
    });

    return (
        <div className={styles.buttons}>
            <Button
                className="hdp-override large"
                data-test-id="continue"
                disabled={!isContinuePossible}
                disableRipple
                variant="contained"
                onClick={() => navigateToStep(nextStepName)}
            >
                Continue
            </Button>
            <Button
                className="hdp-override large hdp-override--grey"
                data-test-id="back"
                disableRipple
                variant="contained"
                onClick={() => navigateToStep(previousStepName)}
            >
                Back
            </Button>
            <RestrictAccess allowPermissions={uploadPermissionsList}>
                <Button
                    className={`hdp-override large hdp-override--grey ${styles.uploadButton}`}
                    data-test-id={formatTestId('upload', 'new file')}
                    disabled={!isResubmissionAllowed}
                    disableRipple
                    variant="contained"
                    component={Link}
                    to={newSubmissionRoute}
                >
                    Upload new file
                </Button>
            </RestrictAccess>
        </div>
    );
};

export default ContinueBackUploadNew;
