import React, { SyntheticEvent } from 'react';
import { AutocompleteChangeReason } from '@mui/material';
import AutocompleteDropdown from 'components/AutocompleteDropdown/AutocompleteDropdown';
import { AutocompleteOption } from 'components/AutocompleteDropdown/AutocompleteDropdown';
import { Provider } from 'types/provider';

import styles from './filters.module.scss';

interface ProviderFilterProps {
    value: string[];
    providers: Provider[];
    onChange: (
        event: SyntheticEvent,
        value: string[],
        reason: AutocompleteChangeReason,
    ) => void;
    inputValue?: string;
    onInputChange?: (value: string) => void;
}

const ProviderFilter = ({
    value,
    providers,
    onChange,
    inputValue,
    onInputChange,
}: ProviderFilterProps) => {
    const sortAlphabetically = (a: Provider, b: Provider) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    };

    const providerOptions: AutocompleteOption[] = [...providers]
        .sort(sortAlphabetically)
        .map(provider => ({
            value: provider.institutionId,
            label: `${provider.institutionId} - ${provider.name}`,
        }));

    const handleChange = (
        event: SyntheticEvent,
        newValue: string[],
        reason: AutocompleteChangeReason,
    ) => {
        onChange(event, newValue, reason);
    };

    return (
        <div className={styles.providerFilterContainer}>
            <AutocompleteDropdown
                id="provider-filter"
                placeholder="Filter by provider"
                options={providerOptions}
                value={value}
                onChange={handleChange}
                label="Filter by Provider"
                inputValue={inputValue}
                onInputChange={onInputChange}
            />
        </div>
    );
};

ProviderFilter.displayName = 'ProviderFilter';

export default ProviderFilter;
