import { get, getFilenameFromContentHeader, getRaw } from 'services/api/utils';
import {
    CredibilityChapter,
    CredibilityPreviousSubmission,
    CredibilityReport,
    CredibilityReportDrilldown,
} from 'types/credibility';

import {
    GetChapterReportDataZipRequestArgs,
    GetCompareDataArgs,
    GetDrillDownDataCsvArgs,
    GetDrillDownDataRequestArgs,
    GetPreviousSubmissionsRequestArgs,
    GetReportDataRequestArgs,
    GetReportDataXlsxArgs,
    GetReportsRequestArgs,
} from './types';

export const getReportData = ({
    reportCode,
    submissionId,
}: GetReportDataRequestArgs) =>
    get(
        `/credibilityapi/credibilityreport/${reportCode}/${submissionId}`,
    ) as Promise<CredibilityReport>;

export const getReports = ({
    submissionId,
    providerId,
}: GetReportsRequestArgs) =>
    get(`/credibility/${submissionId}/get-reports/${providerId}`) as Promise<
        CredibilityChapter[]
    >;

export const getReportDataXlsx = async ({
    reportCode,
    submissionId,
}: GetReportDataXlsxArgs) => {
    const res = await getRaw(
        `/credibilityapi/credibilityreport/${reportCode}/${submissionId}/xlsx`,
        {
            headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        },
    );

    const header = res.headers.get('Content-Disposition');
    const fileName = getFilenameFromContentHeader(header);
    const contents = URL.createObjectURL(await res.blob());
    return { contents, fileName };
};

export const getChapterReportDataZip = async ({
    chapterId,
    submissionId,
}: GetChapterReportDataZipRequestArgs) => {
    const res = await getRaw(
        `/credibilityapi/credibilityreport/${submissionId}/zip?chapterId=${chapterId}`,
        {
            headers: {
                Accept: 'application/zip',
            },
        },
    );

    const header = res.headers.get('Content-Disposition');
    const fileName = getFilenameFromContentHeader(header);
    const contents = URL.createObjectURL(await res.blob());
    return { contents, fileName };
};

export const getAllReportDataZip = async ({
    submissionId,
}: {
    submissionId: string;
}) => {
    const res = await getRaw(
        `/credibilityapi/credibilityreport/${submissionId}/zip`,
        {
            headers: {
                Accept: 'application/zip',
            },
        },
    );

    const header = res.headers.get('Content-Disposition');
    const fileName = getFilenameFromContentHeader(header);
    const contents = URL.createObjectURL(await res.blob());
    return { contents, fileName };
};

export const getPreviousSubmissions = ({
    submissionId,
    collectionId,
    instId,
}: GetPreviousSubmissionsRequestArgs) =>
    get(
        `/credibility/${submissionId}/get-previous-submissions/${collectionId}/${instId}`,
    ) as Promise<CredibilityPreviousSubmission[]>;

export const getCompareData = ({
    reportCode,
    currentSubmissionId,
    previousSubmissionId,
}: GetCompareDataArgs) =>
    get(
        `/credibilityapi/get-compare-data/${reportCode}/${currentSubmissionId}/${previousSubmissionId}`,
    ) as Promise<CredibilityReport>;

export const getDrillDownData = ({
    reportCode,
    submissionId,
    cellId,
    limit,
    offset,
    sortField,
    sortOrder,
}: GetDrillDownDataRequestArgs) => {
    return get(
        `/credibilityapi/credibilityreport/${reportCode}/${submissionId}/drilldown/${cellId}`,
        {
            query: {
                limit,
                sortField,
                sortOrder,
                ...(offset && { offset: offset * limit }),
            },
        },
    ) as Promise<CredibilityReportDrilldown>;
};

export const getDrillDownDataCsv = async ({
    reportCode,
    submissionId,
    cellId,
}: GetDrillDownDataCsvArgs) => {
    const res = await getRaw(
        `/credibilityapi/credibilityreport/${reportCode}/${submissionId}/drilldown/${cellId}/csv`,
        { headers: { Accept: 'text/csv' } },
    );

    const header = res.headers.get('Content-Disposition');
    const fileName = getFilenameFromContentHeader(header);
    const text = await res.text();
    return { text, fileName };
};
