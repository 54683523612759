import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import Loading from 'components/Loading/Loading';
import { Ruleset } from 'queries/rulesets/types';
import useRulesets from 'queries/rulesets/useRulesets';
import { Schema } from 'queries/schemas/types';
import useSchemas from 'queries/schemas/useSchemas';
import {
    CreateSpecificationArgs,
    RulesetWithSchema,
    SchemaRulesetCartesian,
    SpecFieldsErrors,
    SpecFieldsInterface,
} from 'queries/specifications/types';
import useCreateSpecification from 'queries/specifications/useCreateSpecification';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import RulesetsDropdown from './RulesetsDropdown/RulesetsDropdown';
import SchemaDropdown from './SchemaDropdown/SchemaDropdown';
import VersionIncrementDropdown from './VersionIncrementDropdown/VersionIncrementDropdown';

import styles from './createSpecForm.module.scss';

const states = {
    UNINITIALISED: 1,
    LOADING: 2,
    LOADED: 3,
    LOADING_FAILED: 4,
    CREATING: 5,
    CREATED: 6,
    CREATING_FAILED: 7,
};

// Map an array of applicable rulesets to each schema id
const mapRulesetsToSchemas = (
    schemas?: Schema[],
    rulesets?: Ruleset[],
): RulesetWithSchema => {
    if (!schemas || !rulesets) {
        return [];
    }
    const schemasWithIndex = schemas.map(({ id, version }, index) => ({
        id,
        version,
        index,
    }));

    const rulesetsWithIndex = rulesets.map(
        ({ id, version, schema }, index) => ({
            id,
            version,
            index,
            schema,
        }),
    );

    const f = <T, U>(a: T[], b: U[]): Array<[T, U]> =>
        ([] as Array<[T, U]>).concat(
            ...a.map(d => b.map(e => [d, e] as [T, U])),
        );

    const cartesian = (
        a: unknown[],
        b: unknown[],
        ...c: unknown[]
    ): SchemaRulesetCartesian => {
        // @ts-expect-error the spread operator is annoying in typescript
        return b?.length ? cartesian(f(a, b), ...c) : a;
    };

    const cartesianProduct = cartesian(schemasWithIndex, rulesetsWithIndex);

    const data = cartesianProduct.reduce(
        (output: RulesetWithSchema, [schema, ruleset]) => {
            return {
                ...output,
                [schema.id]: {
                    schema,
                    rulesets: [
                        ...(output[schema.id]?.rulesets || []),
                        ...(schema.id === ruleset.schema.id ? [ruleset] : []),
                    ],
                },
            };
        },
        {},
    );
    return data;
};

const determineStatus = (
    schemaStatus: 'pending' | 'success' | 'error',
    rulesetStatus: 'pending' | 'success' | 'error',
) => {
    if (schemaStatus === 'pending' || rulesetStatus === 'pending') {
        return states.LOADING;
    }

    if (schemaStatus === 'success' && rulesetStatus === 'success') {
        return states.LOADED;
    }

    if (schemaStatus === 'error' || rulesetStatus === 'error') {
        return states.LOADING_FAILED;
    }

    return states.UNINITIALISED;
};

const useCreateSpecFields = ({
    onValid,
}: {
    onValid: ({
        schemaId,
        rulesetId,
        versionIncrement,
    }: CreateSpecificationArgs) => void;
}) => {
    const [schemaId, setSchemaId] = useState('');
    const [rulesetId, setRulesetId] = useState('');
    const [versionIncrement, setVersionIncrement] = useState<string | null>(
        null,
    );
    const [errors, setErrors] = useState({
        schemaId: false,
        rulesetId: false,
        versionIncrement: false,
    });

    const fields = {
        schemaId: {
            onChange: (schemaId: string) => setSchemaId(schemaId),
            value: schemaId,
            hasError: (errors: SpecFieldsErrors) =>
                errors.schemaId && schemaId === '',
        },
        rulesetId: {
            onChange: (rulesetId: string) => setRulesetId(rulesetId),
            value: rulesetId,
            hasError: (errors: SpecFieldsErrors) =>
                errors.rulesetId && rulesetId === '',
        },
        versionIncrement: {
            onChange: (versionIncrement: string) => {
                setVersionIncrement(versionIncrement);
            },

            value: versionIncrement,
            hasError: (errors: SpecFieldsErrors) =>
                errors.versionIncrement && versionIncrement === null,
        },
    };

    const deriveFields = () => {
        return Object.entries(fields).reduce((fields, [id, field]) => {
            return {
                ...fields,
                [id]: {
                    ...field,
                    error: field.hasError(errors),
                },
            };
        }, {}) as SpecFieldsInterface;
    };

    const onSubmit = () => {
        const isValid = () => {
            return schemaId && rulesetId && versionIncrement;
        };

        if (isValid()) {
            onValid({
                schemaId: Number(schemaId),
                rulesetId: Number(rulesetId),
                versionIncrement: versionIncrement ?? '',
            });
        } else {
            setErrors({
                schemaId: true,
                rulesetId: schemaId !== '',
                versionIncrement: true,
            });
        }
    };

    return {
        fields: deriveFields(),
        onSubmit,
    };
};

const CreateSpecForm = () => {
    let isLoading = false;
    let isLoadingFailed = false;
    const [creatingStatus, setCreatingStatus] = useState('idle');

    const navigate = useNavigate();

    const { data: schemas, status: schemaStatus } = useSchemas({
        state: ['available'],
    });
    const { data: rulesets, status: rulesetStatus } = useRulesets({
        state: ['available'],
    });

    const data = Object.values(mapRulesetsToSchemas(schemas, rulesets));

    const loadingStatus = determineStatus(schemaStatus, rulesetStatus);

    const { mutate: createSpec } = useCreateSpecification();

    const { fields, onSubmit } = useCreateSpecFields({
        onValid: (data: CreateSpecificationArgs) => {
            setCreatingStatus('pending');
            createSpec(data, {
                onSuccess: () => {
                    navigate('/management/specifications');
                },
                onError: () => {
                    setCreatingStatus('error');
                },
            });
        },
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        onSubmit();
    };

    const handleCancelButtonClicked = () => {
        navigate('/management/specifications');
    };

    if (loadingStatus === states.LOADING) {
        isLoading = true;
    } else if (loadingStatus === states.LOADING_FAILED) {
        isLoadingFailed = true;
    }

    const handleTryAgain = () => {
        window.location.reload();
    };

    if (isLoadingFailed === true) {
        return (
            <>
                <Typography paragraph align={'left'} color={'textSecondary'}>
                    There was a problem loading the create spec form.
                </Typography>
                <Button
                    variant={'outlined'}
                    size={'small'}
                    onClick={handleTryAgain}
                >
                    Try again
                </Button>
            </>
        );
    }

    if (isLoading === true) {
        return <Loading />;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={6} sm={12} md={6}>
                    <SchemaDropdown
                        data={data}
                        fields={fields}
                        isDisabled={creatingStatus === 'pending'}
                    />
                </Grid>
                <Grid item xs={6} sm={12} md={6}>
                    <RulesetsDropdown
                        data={data}
                        fields={fields}
                        isDisabledByDefault={creatingStatus === 'pending'}
                    />
                </Grid>
                <Grid item xs={12}>
                    <VersionIncrementDropdown
                        fields={fields}
                        isDisabled={creatingStatus === 'pending'}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type={'submit'}
                        className={styles.button}
                        variant={'contained'}
                        color={'primary'}
                        size={'small'}
                        disabled={creatingStatus === 'pending'}
                        disableElevation
                        data-test-id={formatTestId('createSpecification')}
                    >
                        {creatingStatus === 'pending'
                            ? 'Creating...'
                            : 'Create specification'}
                    </Button>
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        onClick={handleCancelButtonClicked}
                        data-test-id={formatTestId('cancel')}
                    >
                        Cancel
                    </Button>
                </Grid>
                {creatingStatus === 'error' && (
                    <Grid item xs={12}>
                        <Typography color={'error'}>
                            Sorry, there has been a problem creating a new
                            specification. Please try again.
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

export default CreateSpecForm;
