import { useMemo } from 'react';
import {
    generatePath,
    Link as RouterLink,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import {
    Button,
    Link,
    Skeleton,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import { Table, TableHead } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { SubmissionOutletContextValue } from 'pages/Collections/Submission/types';
import { submissions as submissionsApi } from 'services/api';
import { PATHS } from 'src/constants/constants';
import useSort from 'src/hooks/useSort/useSort';

import styles from './previousSubmissions.module.scss';

const LoadingSkeleton = ({ rowCount }: { rowCount: number }) => {
    return Array(rowCount)
        .fill(undefined)
        .map((_, i) => {
            return (
                <TableRow key={`skeleton-row-${i}`}>
                    <TableCell colSpan={3}>
                        <Skeleton
                            width="55rem"
                            variant="rectangular"
                            aria-label="Loading bar"
                        />
                    </TableCell>
                </TableRow>
            );
        });
};

const ErrorMessage = ({ refetch }: { refetch: () => void }) => {
    return (
        <section className={styles.errorContainer}>
            <h2>Previous Submissions</h2>
            <p>Failed to fetch previous submissions</p>
            <Button onClick={refetch} variant="outlined">
                Retry
            </Button>
        </section>
    );
};

type PreviousSubmissionsProps = {
    collectionId: string;
    instId: string;
};

const PreviousSubmissions = ({
    collectionId,
    instId,
}: PreviousSubmissionsProps) => {
    const { data: currentSubmission } =
        useOutletContext<SubmissionOutletContextValue>();
    const params = useParams();

    const { reference = '' } = params;

    const {
        data: submissions,
        isLoading,
        isError,
        refetch,
    } = useQuery({
        queryKey: ['submissions', { collectionId, instId }],
        queryFn: () => submissionsApi.getSubmissions({ collectionId, instId }),
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    const sortedSubmissions = useSort(submissions, 'desc', 'Uploaded');

    const previousSubmissions = useMemo(() => {
        if (!sortedSubmissions || !currentSubmission) return [];

        return sortedSubmissions
            .filter(
                ({ Uuid, Uploaded }) =>
                    Uuid !== currentSubmission.uuid &&
                    DateTime.fromISO(Uploaded) <
                        DateTime.fromISO(currentSubmission.uploaded),
            )
            .slice(0, 5);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sortedSubmissions,
        currentSubmission?.uuid,
        currentSubmission?.uploaded,
    ]);

    if (isError) return <ErrorMessage refetch={refetch} />;

    return (
        !!previousSubmissions.length && (
            <Table className={styles.table} size="small">
                <caption>Previous Submissions</caption>
                <TableHead className={styles.tableHead}>
                    <TableRow>
                        <TableCell>File Name</TableCell>
                        <TableCell>Submission ID</TableCell>
                        <TableCell>Uploaded</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? (
                        <LoadingSkeleton rowCount={5} />
                    ) : (
                        previousSubmissions.map(submission => (
                            <TableRow key={submission.Uuid}>
                                <TableCell>
                                    <Link
                                        target="_blank"
                                        component={RouterLink}
                                        underline="none"
                                        to={generatePath(PATHS.ADMIN_REPORTS, {
                                            collectionId:
                                                submission.CollectionId.toString(),
                                            submissionId: submission.Uuid,
                                            instId,
                                            reference,
                                        })}
                                    >
                                        {submission.FileName}
                                    </Link>
                                </TableCell>
                                <TableCell>{submission.Uuid}</TableCell>
                                <TableCell>
                                    {DateTime.fromISO(
                                        submission.Uploaded,
                                    ).toLocaleString(DateTime.DATETIME_MED)}
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        )
    );
};

PreviousSubmissions.displayName = 'PreviousSubmissions';

export default PreviousSubmissions;
