import React, { Component, ReactNode } from 'react';

import { Error } from '../Error';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: { children: React.ReactNode }) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    shouldComponentUpdate() {
        if (this.state.hasError) {
            this.setState({ hasError: false });
        }

        return true;
    }

    render() {
        if (this.state.hasError) {
            return <Error />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
