import { useMutation, useQueryClient } from '@tanstack/react-query';
import { specifications as specificationsApi } from 'services/api';
import { GetAllSpecificationsResponse } from 'services/api/specifications';

import { SpecificationInterface, UpdateSpecificationStateArgs } from './types';

export default function useUpdateSpecificationState() {
    const queryClient = useQueryClient();

    const { mutate, ...mutation } = useMutation({
        mutationFn: (args: UpdateSpecificationStateArgs) =>
            specificationsApi.updateState(args),
        onSuccess: (mutationResponse, variables) => {
            queryClient.setQueryData(
                ['specifications', ''],
                (previousData: GetAllSpecificationsResponse) => {
                    return {
                        ...previousData,
                        specifications: previousData.specifications.map(
                            (specification: SpecificationInterface) => {
                                if (specification.id === mutationResponse.id) {
                                    const oldSpecification = specification;
                                    const newSpecification = {
                                        ...oldSpecification,
                                        ...mutationResponse,
                                    };

                                    return newSpecification;
                                }
                                return specification;
                            },
                        ),
                    };
                },
            );
            queryClient.invalidateQueries({
                queryKey: ['specifications', ''],
            });
            queryClient.invalidateQueries({
                queryKey: ['specification', `${variables.id}`],
            });
        },
    });

    return { mutate, ...mutation };
}
