import React, {
    MouseEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    Chip,
    CircularProgress,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TablePaginationProps,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { RestrictAccess } from 'components';
import WithTestId from 'components/WithTestId/WithTestId';
import useRegulators from 'queries/regulator/useRegulators';
import { getRegulatorShortName } from 'queries/regulator/utils';
import { submissions as submissionsApi } from 'services/api';
import { ValidationsSortKey } from 'services/api/submissions/types';
import { useUserContext } from 'src/components/index';
import { PATHS } from 'src/constants/constants';
import {
    calculatePercentage,
    getIssueStatusLabel,
    issueStatuses,
    renderStatus,
} from 'src/pages/Collections/Submission/utils';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { Collection } from 'types/collection';
import { Regulator } from 'types/regulator';
import { ImsIssue, SubmissionValidation } from 'types/submission';

import { SUCCESS } from '../../../../../../constants/imsBatchUpdateStatusIds';

import styles from './qualityRules.module.scss';

const TablePaginationWithTestId =
    WithTestId<TablePaginationProps>(TablePagination);

interface IssueMenuItemProps {
    rule: SubmissionValidation;
    isLatestSubmission: boolean;
    anchorEl: HTMLElement | null;
    issueCreateStatus: string;
    setIssueCreateStatus: (status: string) => void;
    handleClickCloseMenu: () => void;
    disallowCreateIssues: boolean;
    index: number;
    submissionId: string;
    isRestrictedUser?: boolean;
}

const IssueMenuItem = (props: IssueMenuItemProps) => {
    const {
        rule,
        isLatestSubmission,
        anchorEl,
        issueCreateStatus,
        setIssueCreateStatus,
        handleClickCloseMenu,
        disallowCreateIssues,
        index,
        submissionId,
        isRestrictedUser = false,
    } = props;
    const [isCreateIssueDisabled, setIsCreateIssueDisabled] =
        useState<boolean>();
    const [isCreateIssueVisible, setIsCreateIssueVisible] = useState<boolean>();
    const [isViewIssueVisible, setIsViewIssueVisible] = useState<boolean>();

    const updateMenuState = (
        issue: ImsIssue | undefined,
        localStatus: string,
        isRestrictedUser: boolean,
        isLatestSubmission: boolean,
        disallowCreateIssues: boolean,
    ) => {
        if (issue && issue.imsBatchStatusId === SUCCESS) {
            setIsCreateIssueDisabled(true);
            setIsCreateIssueVisible(true);
            setIsViewIssueVisible(true);
            return;
        }

        if (
            localStatus === issueStatuses.PENDING ||
            localStatus === issueStatuses.CREATED
        ) {
            setIsCreateIssueDisabled(true);
            setIsCreateIssueVisible(true);
            setIsViewIssueVisible(false);
            return;
        }

        setIsCreateIssueDisabled(isRestrictedUser || !isLatestSubmission);
        setIsCreateIssueVisible(true);
        setIsViewIssueVisible(false);

        if (disallowCreateIssues) {
            setIsCreateIssueDisabled(true);
        }
    };

    const handleClickCreateIssue = async () => {
        setIssueCreateStatus(issueStatuses.PENDING);
        handleClickCloseMenu();
        try {
            const results = await submissionsApi.createIssue({
                validationCode: rule.Code,
                regulatorCode: rule.RegulatorCode,
                submissionUuid: submissionId,
            });
            rule.issue = results;

            setIssueCreateStatus(issueStatuses.CREATED);
            setIsCreateIssueDisabled(true);
            setIsCreateIssueVisible(false);
        } catch (error) {
            setIssueCreateStatus(issueStatuses.FAILED);
        }
    };

    useEffect(() => {
        updateMenuState(
            rule.issue,
            issueCreateStatus,
            isRestrictedUser,
            isLatestSubmission,
            disallowCreateIssues,
        );
    }, [
        isRestrictedUser,
        rule.issue,
        issueCreateStatus,
        isLatestSubmission,
        disallowCreateIssues,
    ]);

    return (
        <Menu
            anchorEl={anchorEl}
            id="ims-menu"
            className={styles.imsMenu}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClickCloseMenu}
            classes={{
                paper: 'hdp-override three-dot-menu',
            }}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            autoFocus={true}
        >
            <RestrictAccess allowPermissions={['collections.view-issue']}>
                {isViewIssueVisible && (
                    <MenuItem
                        tabIndex={0}
                        data-test-id={formatTestId(
                            'view issue',
                            `quality rule ${index}`,
                        )}
                        // On click, send the click to the child anchor element
                        // to open the issue in a new tab
                        onClick={() => {
                            const anchor = document.querySelector(
                                `a[id="${formatTestId(
                                    'view issue',
                                    `quality rule ${index}`,
                                )}"]`,
                            ) as HTMLElement;
                            anchor?.click();
                        }}
                    >
                        <a
                            href={rule.issue?.imsIssueUrl}
                            target="_blank"
                            id={formatTestId(
                                'view issue',
                                `quality rule ${index}`,
                            )}
                            className={styles.link}
                            rel="noreferrer"
                        >
                            View issue
                        </a>
                    </MenuItem>
                )}
            </RestrictAccess>
            {isCreateIssueVisible && (
                <MenuItem
                    tabIndex={0}
                    disabled={isCreateIssueDisabled}
                    onClick={handleClickCreateIssue}
                    data-test-id={
                        !isCreateIssueDisabled
                            ? formatTestId(
                                  'create issue',
                                  `quality rule ${index}`,
                              )
                            : null
                    }
                >
                    Create issue
                </MenuItem>
            )}
        </Menu>
    );
};

interface QualityRuleRowProps {
    rule: SubmissionValidation;
    regulators: Regulator[];
    submissionId: string;
    instId: string;
    collection?: Collection;
    collectionId: string;
    reference: string;
    isLatestSubmission: boolean;
    disallowCreateIssues: boolean;
    index: number;
}

const QualityRuleRow = ({
    rule,
    regulators,
    collection,
    instId,
    submissionId,
    isLatestSubmission,
    disallowCreateIssues,
    index,
}: QualityRuleRowProps) => {
    const { collectionId = '', reference = '' } = useParams();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [issueCreateStatus, setIssueCreateStatus] = useState<string>('');
    const { isAdmin, isSpecialCategory, isAnalyst } = useUserContext();

    const issueStatusLabel = rule.issue
        ? getIssueStatusLabel(rule.issue, issueCreateStatus)
        : '';

    const handleClickOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(e.currentTarget);

    const handleClickCloseMenu = useCallback(
        () => setAnchorEl(null),
        [setAnchorEl],
    );

    const issueMenuProps = useMemo(
        () => ({
            rule,
            isLatestSubmission,
            anchorEl,
            issueCreateStatus,
            setIssueCreateStatus,
            handleClickCloseMenu,
            disallowCreateIssues,
            index,
            submissionId,
        }),
        [
            rule,
            isLatestSubmission,
            anchorEl,
            issueCreateStatus,
            setIssueCreateStatus,
            handleClickCloseMenu,
            disallowCreateIssues,
            index,
            submissionId,
        ],
    );

    return (
        <>
            <TableCell align="left">
                {getRegulatorShortName(rule.RegulatorCode, regulators)}
            </TableCell>
            <TableCell align="left">
                {rule.ConjoinedTolerances}
                {rule.IsProviderToleranceOverride && (
                    <Chip
                        size="small"
                        label="Override"
                        className={styles.overrideChip}
                    />
                )}
            </TableCell>
            <TableCell align="left">{rule.PopulationCount}</TableCell>
            <TableCell align="left" className={styles.error}>
                <Link
                    className={styles.error}
                    data-test-id={formatTestId('linkTo', `rule ${index}`)}
                    to={generatePath(
                        isAdmin || isSpecialCategory || isAnalyst
                            ? PATHS.ADMIN_QUALITY_RULE
                            : PATHS.QUALITY_RULE,
                        {
                            collectionId:
                                collection?.id.toString() || collectionId,
                            reference: collection?.reference || reference,
                            instId,
                            submissionId,
                            rule: rule.Code,
                            regulatorCode: rule.RegulatorCode,
                        },
                    )}
                >
                    {rule.FailureCount} (
                    {calculatePercentage(
                        rule.FailureCount,
                        rule.PopulationCount,
                    )}
                    %)
                </Link>
            </TableCell>
            <TableCell align="left">
                {issueStatusLabel && (
                    <span className={styles.chip}>{issueStatusLabel}</span>
                )}
            </TableCell>
            <TableCell align="center">
                <IconButton
                    aria-label="create-issue-menu-options"
                    disableRipple={true}
                    aria-haspopup="true"
                    onClick={handleClickOpenMenu}
                    size="large"
                    className={styles.imsMenu}
                    data-test-id={formatTestId(
                        'three dot menu',
                        `row ${index}`,
                    )}
                >
                    <MoreVertIcon style={{ color: '#006dff' }} />
                </IconButton>
                <Menu
                    id="new-issue-menu"
                    classes={{
                        paper: 'hdp-override empty',
                    }}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClickCloseMenu}
                    data-test-id={formatTestId(
                        'quality rule options',
                        `row ${index}`,
                    )}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <RestrictAccess
                        allowPermissions={['collections.create-issue']}
                        fallBackComponent={
                            <IssueMenuItem
                                isRestrictedUser
                                {...issueMenuProps}
                            />
                        }
                    >
                        <IssueMenuItem {...issueMenuProps} />
                    </RestrictAccess>
                </Menu>
            </TableCell>
        </>
    );
};

interface QualityRulesProps {
    data: (SubmissionValidation & {
        specialistRegulators?: SubmissionValidation[];
    })[];
    pagingData: { totalResultsCount: number; limit: number; offset: number };
    collection?: Collection;
    instId: string;
    submissionId: string;
    isLatestSubmission: boolean;
    sortHandler: (
        column: 'POPULATION' | 'FAILURE' | 'TOLERANCE' | 'STATUS' | 'ID',
        direction: 'asc' | 'desc',
        page: number,
    ) => void;
    pageHandler: (page: number, limit: number) => void;
    activeSort: string;
    activeSortDirection: 'asc' | 'desc';
    noResultsFound: boolean;
    disallowCreateIssues: boolean;
    selectedStatus: 0 | 1;
    noMatchingResults: boolean;
    searchedEmptyResults: boolean;
}

const QualityRules = ({
    data,
    pagingData,
    collection,
    instId,
    submissionId,
    isLatestSubmission,
    sortHandler,
    pageHandler,
    activeSort,
    activeSortDirection,
    noResultsFound,
    disallowCreateIssues,
    selectedStatus,
    noMatchingResults,
    searchedEmptyResults,
}: QualityRulesProps) => {
    const { data: regulators } = useRegulators();
    const { collectionId = '', reference = '' } = useParams();
    const [currentStatus, setCurrentStatus] = React.useState('outside');
    useEffect(() => {
        setCurrentStatus(selectedStatus === 1 ? 'outside' : 'inside');
    }, [selectedStatus]);
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => pageHandler(0, parseInt(event.target.value, 10));

    const handleChangePage = (
        _: React.MouseEvent<HTMLButtonElement> | null,
        page: number,
    ) => pageHandler(page, pagingData.limit);

    const handleHeaderClickedChild = (event: MouseEvent<HTMLSpanElement>) => {
        const newColumn = event.currentTarget.id as ValidationsSortKey;
        let newSortDirection = activeSortDirection;
        if (newColumn === activeSort) {
            if (activeSortDirection === 'asc') {
                newSortDirection = 'desc';
            } else {
                newSortDirection = 'asc';
            }
        }
        sortHandler(newColumn, newSortDirection, 0);
    };

    const generateText = () => {
        if (noResultsFound && noMatchingResults) {
            return `No results found because your quality report contains no rule failures which are ${currentStatus} tolerance.`;
        }
        if (noResultsFound && searchedEmptyResults) {
            return 'No results found for that criteria';
        }
        if (noResultsFound) {
            return `Your quality report contains no rule failures which are ${currentStatus} tolerance.`;
        }
    };
    return !data?.length || regulators === undefined ? (
        <div className={styles.loading}>
            {!noResultsFound ? (
                <CircularProgress aria-label="Loading quality rules report" />
            ) : (
                generateText()
            )}
        </div>
    ) : (
        <Paper className={styles.qualityRules}>
            <Table data-test-id={formatTestId('table', 'quality rules')}>
                <thead>
                    <TableRow className={styles.headerRow}>
                        <TableCell
                            className={`${styles.header} ${styles.interactive}`}
                            data-test-id={formatTestId('sortBy', 'rule')}
                            component="th"
                            scope="col"
                        >
                            <TableSortLabel
                                active={activeSort === 'ID'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                onClick={handleHeaderClickedChild}
                                id="ID"
                            >
                                Rule
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ minWidth: 100 }}
                            className={`${styles.header} ${styles.interactive}`}
                            data-test-id={formatTestId('sortBy', 'status')}
                            component="th"
                            scope="col"
                        >
                            <TableSortLabel
                                id="STATUS"
                                onClick={handleHeaderClickedChild}
                                active={activeSort === 'STATUS'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                            >
                                Status
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="right"
                            className={styles.header}
                            component="th"
                            scope="col"
                        >
                            Regulator
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ minWidth: 125 }}
                            className={`${styles.header} ${styles.interactive}`}
                            data-test-id={formatTestId('sortBy', 'tolerance')}
                            component="th"
                            scope="col"
                        >
                            <TableSortLabel
                                id="TOLERANCE"
                                active={activeSort === 'TOLERANCE'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                onClick={handleHeaderClickedChild}
                            >
                                Tolerance
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ minWidth: 130 }}
                            className={`${styles.header} ${styles.interactive}`}
                            data-test-id={formatTestId('sortBy', 'population')}
                            component="th"
                            scope="col"
                        >
                            <TableSortLabel
                                id="POPULATION"
                                active={activeSort === 'POPULATION'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                onClick={handleHeaderClickedChild}
                            >
                                Population
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="left"
                            style={{ minWidth: 110 }}
                            className={`${styles.header} ${styles.interactive}`}
                            data-test-id={formatTestId('sortBy', 'failure')}
                            component="th"
                            scope="col"
                        >
                            <TableSortLabel
                                id="FAILURE"
                                active={activeSort === 'FAILURE'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                onClick={handleHeaderClickedChild}
                            >
                                Failure
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            id="ISSUE_STATUS"
                            align="left"
                            style={{ minWidth: 160 }}
                            className={styles.header}
                            component="th"
                            scope="col"
                        >
                            {`Issue status`}
                        </TableCell>
                        <TableCell
                            id="CREATE_ISSUE_MENU"
                            align="center"
                            component="td"
                        >
                            {``}
                        </TableCell>
                    </TableRow>
                </thead>
                <TableBody>
                    {data.map((row, index) => (
                        <React.Fragment
                            key={`${row.Code}-${row.RegulatorCode}`}
                        >
                            <TableRow
                                hover
                                className={styles.row}
                                data-test-id={formatTestId(
                                    'quality rule',
                                    `row ${index + 1}`,
                                )}
                            >
                                <TableCell>
                                    <span className={styles.title}>
                                        {row.Code}
                                    </span>
                                    <br />
                                    {row.Name}
                                </TableCell>
                                <TableCell align="left">
                                    {renderStatus(row.Status)}
                                </TableCell>
                                <QualityRuleRow
                                    rule={row}
                                    regulators={regulators}
                                    submissionId={submissionId}
                                    isLatestSubmission={isLatestSubmission}
                                    collection={collection}
                                    instId={instId}
                                    disallowCreateIssues={disallowCreateIssues}
                                    index={index + 1}
                                    collectionId={
                                        collection?.id.toString() ||
                                        collectionId
                                    }
                                    reference={
                                        collection?.reference || reference
                                    }
                                />
                            </TableRow>
                            {row.specialistRegulators &&
                                row.specialistRegulators.map(
                                    (reg: SubmissionValidation) => (
                                        <TableRow key={reg.RegulatorCode}>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <QualityRuleRow
                                                rule={reg}
                                                regulators={regulators}
                                                submissionId={submissionId}
                                                isLatestSubmission={
                                                    isLatestSubmission
                                                }
                                                collection={collection}
                                                disallowCreateIssues={
                                                    disallowCreateIssues
                                                }
                                                index={index + 1}
                                                instId={instId}
                                                reference={
                                                    collection?.reference ||
                                                    reference
                                                }
                                                collectionId={
                                                    collection?.id.toString() ||
                                                    collectionId
                                                }
                                            />
                                        </TableRow>
                                    ),
                                )}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            <TablePaginationWithTestId
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={pagingData?.totalResultsCount || 0}
                rowsPerPage={pagingData?.limit || 10}
                page={pagingData?.offset / pagingData?.limit || 0}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                testIdProps={{
                    backIconButtonProps: {
                        'data-test-id': formatTestId(
                            'previous page',
                            'quality rules table',
                        ),
                    },
                    nextIconButtonProps: {
                        'data-test-id': formatTestId(
                            'next page',
                            'quality rules table',
                        ),
                    },
                    SelectProps: {
                        'data-test-id': formatTestId(
                            'page options',
                            'quality rules table',
                        ),
                    },
                }}
            />
        </Paper>
    );
};

export default QualityRules;
