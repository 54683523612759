import { ChangeEvent, SyntheticEvent } from 'react';
import { useParams } from 'react-router';
import { Box, Grid, Typography } from '@mui/material';
import useTableParams from 'hooks/useTableParams/useTableParams';
import ProviderFilter from 'pages/Monitoring/SubmissionSummary/Filters/ProviderFilter';
import useCollectionActivityLog from 'queries/collections/useCollectionActivityLog';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import { Provider } from 'types/provider';

import Loading from '../../Loading/Loading';
import ActivityLogTable from '../ActivityLogTable/ActivityLogTable';

import styles from './collectionsActivityLog.module.scss';

const DEFAULT_TABLE_PARAMETERS = {
    offset: 0,
    limit: 10,
    selectedProviders: [],
};

interface CollectionsActivityLogTableParams {
    offset: number;
    limit: number;
    selectedProviders: string[];
}

/* Renders when logged in as an admin user
 -> Monitoring -> Collection -> Activity Log Dropdown
 */
const CollectionsActivityLog = ({ providers }: { providers: Provider[] }) => {
    const { reference } = useParams<{ reference: string }>();

    const tableId = TABLE_PARAMS_IDS.COLLECTIONS_ACTIVITY_LOG;
    const { values: tableParams, methods: tableParamsMethods } =
        useTableParams<CollectionsActivityLogTableParams>(
            tableId,
            DEFAULT_TABLE_PARAMETERS,
        );

    const { limit, offset, selectedProviders } = tableParams;
    const { setLimit, setOffset, setSelectedProviders } = tableParamsMethods;

    const {
        data: activityLog,
        isLoading,
        isError,
    } = useCollectionActivityLog({
        collectionReference: reference || '',
        limit,
        offset,
    });

    const activityLogRecords = activityLog?.records
        .map(entry => ({
            ...entry,
            providerName:
                providers.find(p => p.institutionId === entry.instId)?.name ||
                '-',
        }))
        .filter(entry =>
            selectedProviders.length > 0
                ? selectedProviders.includes(entry.instId)
                : true,
        );

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setLimit(+event.target.value);
        setOffset(0);
    };

    const handleChangePage = (newOffset: number) => {
        setOffset(newOffset * limit);
    };

    const handleSelectProviders = (_: SyntheticEvent, newValue: string[]) => {
        setSelectedProviders(newValue);
        setOffset(0);
    };

    if (isError) {
        return (
            <Box mt={3}>
                <Typography>
                    Could not load Activity Log, please contact Jisc
                    Administrator.
                </Typography>
            </Box>
        );
    }

    if (isLoading) return <Loading />;

    if (!activityLogRecords || !activityLog) {
        return (
            <Typography className={styles.noResults}>
                No results found.
            </Typography>
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div className={styles.actionsBar}>
                    <ProviderFilter
                        value={selectedProviders}
                        providers={providers}
                        onChange={handleSelectProviders}
                    />
                </div>
                <ActivityLogTable
                    kind="collection"
                    activityLogEntries={{
                        records: activityLogRecords,
                        pagingMetadata: activityLog.pagingMetadata,
                    }}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleChangePage={(e, page) => handleChangePage(page)}
                />
            </Grid>
        </Grid>
    );
};

export default CollectionsActivityLog;
