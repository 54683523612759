import { generatePath, UIMatch } from 'react-router-dom';
import { DateTime } from 'luxon';
import { collectionStates } from 'src/constants/collections';
import {
    FILE_STATUS,
    fileStatusByKey,
    ifStatusIdIsInApproval,
    ifStatusIdIsInProcessing,
    ifStatusIdIsInSignOff,
    ifStatusIdIsUpToQualityProcessingComplete,
} from 'src/constants/FileStatus';
import { Collection } from 'types/collection';
import {
    ImsIssue,
    RawSubmission,
    SubmissionApprovalState,
} from 'types/submission';

import { OVTPATHS, PATHS } from '../../../constants/constants';
import { FAILED, PENDING } from '../../../constants/imsBatchUpdateStatusIds';
import * as filesApi from '../../../services/api/files';

import { StepName } from './Steps/Stepper/types';

interface SchemaErrorsDownloadParams {
    submissionId: string;
    instId: string;
    fileName: string;
    uploaded: string;
}

export const requestSchemaErrorsFileDownload = async ({
    submissionId,
    instId,
    fileName,
    uploaded,
}: SchemaErrorsDownloadParams): Promise<void> => {
    const uploadedDate = DateTime.fromISO(uploaded);
    const fullPath = `${instId}/${uploadedDate.year}/${uploadedDate.month}/${uploadedDate.day}`;
    const csvFileName = `${fileName.substr(0, fileName.lastIndexOf('.'))}.csv`;
    const schemaErrorsFileName = `SchemaErrors_${submissionId}_${csvFileName}`;
    const key = await filesApi.downloadRequest(
        `${fullPath}/${schemaErrorsFileName}`,
    );

    const link = document.createElement('a');
    link.href = key.data;
    link.setAttribute('download', `${schemaErrorsFileName}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const requestSignOffFormFileDownload = async (hash: string) => {
    const key = await filesApi.signOffFormDownloadRequest(hash);
    const link = document.createElement('a');
    link.href = key.data;
    link.setAttribute('download', `${hash}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const requestAllQualityRuleFailuresDownload = async (
    submissionUuid: string,
) => {
    const { url } = await filesApi.downloadAllQualityRuleFailures(
        submissionUuid,
    );
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const requestQualityRuleFailuresDownload = async (uri: string) => {
    const link = document.createElement('a');
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const calculatePercentage = (
    numerator: number,
    denominator: number,
    precision = 2,
) => parseFloat(((numerator / denominator) * 100).toFixed(precision));

export function renderStatus(status: boolean) {
    switch (status) {
        case true:
            return 'Outside';
        case false:
            return 'Inside';
        default:
            return 'Outside';
    }
}

export const RiskLevel2 = '02';
export const RiskLevel1 = '01';
export const CountryCodeEngland = 'E';

export const getApprovalStates = (
    riskCode?: string,
    countryCode?: string,
): SubmissionApprovalState[] | undefined => {
    if (!riskCode || !countryCode) return undefined;

    const analystApproval = {
        ...FILE_STATUS.HESA_ANALYST_APPROVAL,
        code: 'HESA_ANALYST_APPROVAL',
        name: FILE_STATUS.HESA_ANALYST_APPROVAL.label,
    };
    const hesaApproval = {
        ...FILE_STATUS.HESA_APPROVAL,
        code: 'HESA_APPROVAL',
        name: FILE_STATUS.HESA_APPROVAL.label,
    };
    const scApproval = {
        ...FILE_STATUS.SC_APPROVAL,
        code: 'SC_APPROVAL',
        name: FILE_STATUS.SC_APPROVAL.label,
    };

    // High Risk England
    if (riskCode === RiskLevel2 && countryCode === CountryCodeEngland) {
        return [analystApproval, hesaApproval, scApproval];
    }
    // High Risk - Welsh, Scottish, NI
    if (riskCode === RiskLevel2 && countryCode !== CountryCodeEngland) {
        return [analystApproval, hesaApproval];
    }
    // Standard - English
    if (riskCode === RiskLevel1 && countryCode === CountryCodeEngland) {
        return [hesaApproval, scApproval];
    }
    // Standard - Welsh, Scottish, NI
    if (riskCode === RiskLevel1 && countryCode !== CountryCodeEngland) {
        return [hesaApproval];
    }
};

export const issueStatuses = {
    PENDING: 'Pending',
    CREATED: 'Created',
    FAILED: 'Failed to create',
};

export const getIssueStatusLabel = (
    issue: ImsIssue,
    localIssueStatus: string,
) => {
    if (!issue && localIssueStatus) return localIssueStatus;
    else if (issue) {
        if (issue.issueClosed) return issue.resolution;
        else if (issue.resolution === 'Open') return issue.status;
        else if (issue.imsBatchStatusId === PENDING) return 'In Progress';
        else if (issue.imsBatchStatusId === FAILED) return 'Failed to create';
    }

    return null;
};

interface PathArguments {
    instId?: string;
    collectionId: number;
    submissionId: string;
    reference?: string;
}

interface StepPathResult {
    path: string;
    adminPath: string;
}

const getStepPaths = (
    providerPath: string,
    admin: string,
    pathArgs: PathArguments,
): StepPathResult => {
    const {
        instId = '',
        collectionId,
        submissionId,
        reference = '',
    } = pathArgs;
    const path = generatePath(providerPath, { collectionId, submissionId });
    const adminPath = generatePath(admin, {
        instId,
        collectionId,
        submissionId,
        reference,
    });

    return {
        path,
        adminPath,
    };
};

interface PathArgs {
    instId?: string;
    collectionId: number;
    submissionId: string;
    reference?: string;
}

interface StepPath {
    path: string;
    adminPath: string;
}

interface StepPaths {
    Submissions: StepPath;
    Upload: StepPath;
    Processing: StepPath;
    'Quality Assurance': StepPath;
    Submit?: StepPath;
    Approval?: StepPath;
    'Sign Off'?: StepPath;
}

export const getStepRoutes = (ovt: boolean, pathArgs: PathArgs): StepPaths => {
    const paths = ovt ? OVTPATHS : PATHS;

    const Submissions = getStepPaths(
        paths.SUBMISSIONS,
        paths.MONITORING_REFERENCE,
        pathArgs,
    );
    const Upload = getStepPaths(paths.UPLOAD, paths.ADMIN_UPLOAD, pathArgs);
    const Processing = getStepPaths(
        paths.PROCESSING,
        paths.ADMIN_PROCESSING,
        pathArgs,
    );
    const QualityAssurance = getStepPaths(
        paths.REPORTS,
        paths.ADMIN_REPORTS,
        pathArgs,
    );

    const Submit = getStepPaths(PATHS.SUBMIT, PATHS.ADMIN_SUBMIT, pathArgs);
    const Approval = getStepPaths(
        PATHS.APPROVAL,
        PATHS.ADMIN_APPROVAL,
        pathArgs,
    );
    const SignOff = getStepPaths(
        PATHS.PROVIDER_SIGN_OFF,
        PATHS.ADMIN_PROVIDER_SIGN_OFF,
        pathArgs,
    );

    const ovtSteps = {
        Submissions,
        Upload,
        Processing,
        'Quality Assurance': QualityAssurance,
    };
    return ovt
        ? ovtSteps
        : { ...ovtSteps, Submit, Approval, 'Sign Off': SignOff };
};

export const getSubmissionLinkForStep = ({
    status,
    isOvt,
    isStatutoryCustomer,
    ...pathArgs
}: {
    status: string;
    isOvt: boolean;
    isStatutoryCustomer: boolean;
    instId?: string;
    collectionId: number;
    submissionId: string;
    reference?: string;
}) => {
    const stepPaths = getStepRoutes(isOvt, pathArgs);

    const fileStatus = fileStatusByKey(status);

    if (ifStatusIdIsInSignOff(fileStatus.id)) {
        return isStatutoryCustomer
            ? stepPaths['Quality Assurance']
            : stepPaths['Sign Off'];
    }

    if (ifStatusIdIsInApproval(fileStatus.id)) {
        return isStatutoryCustomer
            ? stepPaths['Quality Assurance']
            : stepPaths.Approval;
    }

    if (ifStatusIdIsUpToQualityProcessingComplete(fileStatus.id))
        return stepPaths['Quality Assurance'];

    if (ifStatusIdIsInProcessing(fileStatus.id)) return stepPaths.Processing;

    return stepPaths.Upload;
};

export const inferStepLabelFromRoute = (
    matches: UIMatch<unknown, { crumb: StepName }>[],
    steps: StepName[],
): StepName | undefined => {
    const crumbs: StepName[] =
        matches?.map(match => match?.handle?.crumb) || [];

    const matchingStep = crumbs.find(crumb => steps.includes(crumb));
    return matchingStep;
};

export const collectionContainsNilReturnSubmission = (collection: Collection) =>
    collection.collectionStatus === 'NIL_RETURN';

export const collectionIsOpenOrHistoricAmendment = (collection: Collection) => {
    return (
        collection.state?.id === collectionStates.OPEN ||
        collection.state?.id === collectionStates.HISTORIC_AMENDMENT
    );
};

export const collectionContainsSubmissions = (collection: Collection) => {
    return collection.collectionSubmissionCount > 0;
};

export const collectionAllowsSubmissions = (collection: Collection) => {
    return (
        collectionIsOpenOrHistoricAmendment(collection) &&
        collection.allowSubmissions
    );
};

export const collectionContainsSignedOffHistoricAmendment = (
    collection: Collection,
) => {
    return (
        collection.state.name === 'Historic amendment' &&
        collection.collectionStatus === 'SIGNED_OFF'
    );
};
export const collectionIsLocked = (collection: Collection) => {
    return (
        !collectionAllowsSubmissions(collection) ||
        collectionContainsNilReturnSubmission(collection) ||
        collectionContainsSignedOffHistoricAmendment(collection)
    );
};

export const formatDate = (date: string) =>
    DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);

export const formatDateTime = (date: string) =>
    DateTime.fromISO(date).toFormat('dd LLL yyyy HH:mm');

export const generateUploadText = (
    submission: RawSubmission,
    submissions: RawSubmission[],
) => {
    if (submission.CopiedFrom === null) {
        return formatDateTime(submission.Uploaded);
    } else {
        const copiedFromSubmission = submissions.find(
            sub => sub.SubmissionId === submission.CopiedFrom,
        );

        if (!copiedFromSubmission) {
            return formatDateTime(submission.Uploaded);
        }
        return `${formatDateTime(
            copiedFromSubmission.Uploaded,
        )} (Reprocessed: ${formatDateTime(submission.Uploaded)})`;
    }
};
