import React from 'react';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    NativeSelect,
} from '@mui/material';
import {
    SchemasAndRulesetsReturn,
    SpecFieldsInterface,
} from 'queries/specifications/types';
import { formatTestId } from 'utils/formatTestId/formatTestId';

interface RulesetsDropdownProps {
    isDisabledByDefault?: boolean;
    data: Pick<SchemasAndRulesetsReturn, 'data'>['data'];
    fields: SpecFieldsInterface;
}

const RulesetsDropdown = ({
    isDisabledByDefault = false,
    data,
    fields,
}: RulesetsDropdownProps) => {
    const { onChange, value, error } = fields.rulesetId;

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        console.log('e', e);
        onChange(e.target.value);
    };

    const options =
        data
            .find(({ schema }) => String(schema.id) === fields.schemaId.value)
            ?.rulesets.map(ruleset => {
                return {
                    name: ruleset.version,
                    value: ruleset.id,
                };
            }) || [];

    const hasOptions = options.length > 0;
    const defaultOption = {
        value: '',
        name: hasOptions ? 'Choose' : '',
    };

    const isDisabled = !hasOptions || isDisabledByDefault;

    return (
        <FormControl disabled={isDisabled} error={error && !isDisabled}>
            <FormLabel>Ruleset version</FormLabel>
            <NativeSelect
                onChange={handleChange}
                value={value}
                data-test-id={formatTestId('openMenu', 'rulesetVersion')}
                inputProps={{
                    'aria-label': 'Ruleset version',
                }}
            >
                {[defaultOption, ...options].map(option => (
                    <option
                        key={option.name}
                        value={option.value}
                        data-test-id={formatTestId(
                            'rulesetOption',
                            option.name,
                        )}
                    >
                        {option.name}
                    </option>
                ))}
            </NativeSelect>
            {error && !isDisabled && (
                <FormHelperText>Choose a ruleset version</FormHelperText>
            )}
        </FormControl>
    );
};

export default RulesetsDropdown;
