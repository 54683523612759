import { CircularProgress } from '@mui/material';
import useCredibilityReports from 'queries/credibility/useCredibilityReports';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { Collection } from 'types/collection';

import CredibilityReportListItem from './CredibilityReportListItem';

import styles from './credibilityReports.module.scss';

interface CredibilityReportsProps {
    collection?: Collection;
    submissionId: string;
    instId: string;
    handleClickDownloadChapterReports: (chapterId: number) => void;
}

const CredibilityReports = ({
    collection,
    submissionId,
    instId,
    handleClickDownloadChapterReports,
}: CredibilityReportsProps) => {
    const { data, isLoading, isError } = useCredibilityReports({
        submissionId,
        providerId: instId,
    });

    if (isError) {
        return (
            <div className={styles.loading}>
                No results found for this submission.
            </div>
        );
    }

    if (isLoading || !data) {
        return (
            <div className={styles.loading}>
                <CircularProgress aria-label="Loading credibility reports" />
            </div>
        );
    }

    return (
        <ul
            className={styles.table}
            data-test-id={formatTestId('table', 'credibility reports')}
        >
            {data.map((row, index) => (
                <CredibilityReportListItem
                    key={row.chapterId}
                    row={row}
                    index={index}
                    handleClickDownloadChapterReports={
                        handleClickDownloadChapterReports
                    }
                    collection={collection}
                    submissionId={submissionId}
                    instId={instId}
                />
            ))}
        </ul>
    );
};

export default CredibilityReports;
