import React, { SyntheticEvent, useState } from 'react';
import {
    AutocompleteChangeReason,
    useAutocomplete,
    UseAutocompleteProps,
    UseAutocompleteReturnValue,
} from '@mui/material';

import CustomInput from './CustomInput/CustomInput';
import OptionsList from './OptionsList/OptionsList';

import styles from './autocompleteDropdown.module.scss';

export interface AutocompleteOption {
    value: string; // Keep all values as strings for consistency. Remember to convert your options!
    label: string;
}

interface AutocompleteDropdownProps {
    options: AutocompleteOption[];
    value: string[];
    id: string;
    onChange: (
        event: React.SyntheticEvent,
        value: string[],
        reason: AutocompleteChangeReason,
    ) => void;
    placeholder?: string;
    disabled?: boolean;
    label: string;
    inputValue?: string;
    onInputChange?: (value: string) => void;
}

function filterOptionsByInputValue(
    options: AutocompleteOption[],
    inputValue: string,
) {
    return options.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
}

export default function AutocompleteDropdown({
    options: optionsProp,
    value = [],
    id,
    onChange,
    placeholder,
    disabled,
    label,
    inputValue: inputValueProp,
    onInputChange,
}: AutocompleteDropdownProps) {
    const [uncontrolledInputValue, setUncontrolledInputValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const isControlled =
        inputValueProp !== undefined && onInputChange !== undefined;

    const inputValue = isControlled ? inputValueProp : uncontrolledInputValue;

    // We need to allow for the input value to be controlled outside of the component.
    // This is to allow for the 'Clear all filters' button to work, this button is found in `src/pages/Monitoring/SubmissionSummary/Filters/Filters.tsx`
    const setInputValue = (newInputValue: string) => {
        if (isControlled) {
            onInputChange(newInputValue);
        } else {
            setUncontrolledInputValue(newInputValue);
        }
    };

    const handleChange = (
        event: SyntheticEvent,
        newValue: string[],
        reason: AutocompleteChangeReason,
    ) => {
        onChange(event, newValue, reason);
    };

    const filteredOptions = filterOptionsByInputValue(
        optionsProp,
        inputValue,
    ).map(option => option.value);

    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        setAnchorEl,
        focused,
        expanded,
        getClearProps,
    } = useAutocomplete({
        id,
        options: [...optionsProp.map(option => option.value)],
        value,
        multiple: true,
        inputValue,
        onInputChange: (_, newInputValue, reason) => {
            if (reason !== 'reset') {
                setInputValue(newInputValue);
            }
        },
        open: isOpen,
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
        onChange: handleChange as UseAutocompleteProps<
            string,
            true,
            false,
            false
        >['onChange'],
        disableCloseOnSelect: filteredOptions.length > 1,
        filterOptions: () => {
            return filteredOptions;
        },
        disabled,
    });

    const handleClearClick = () => {
        const clearProps = getClearProps();
        if (clearProps.onClick) {
            clearProps.onClick({} as React.MouseEvent<HTMLButtonElement>);
        }
    };

    const typedGetOptionProps = getOptionProps as UseAutocompleteReturnValue<
        string,
        true,
        false,
        false
    >['getOptionProps'];

    return (
        <div className={styles.container}>
            <div {...getRootProps()}>
                <CustomInput
                    getInputProps={getInputProps}
                    inputValue={inputValue}
                    focused={focused}
                    expanded={expanded}
                    setAnchorEl={setAnchorEl}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    value={value}
                    placeholder={placeholder ?? ''}
                    handleClearClick={handleClearClick}
                    label={label}
                />
            </div>
            {isOpen && (
                <OptionsList
                    groupedOptions={groupedOptions}
                    options={optionsProp}
                    id={id}
                    getOptionProps={renderedOption => ({
                        ...typedGetOptionProps(renderedOption),
                        key: renderedOption.option,
                    })}
                    value={value}
                    getListboxProps={getListboxProps}
                />
            )}
        </div>
    );
}
