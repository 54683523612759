import {
    CreateSpecificationArgs,
    GetSpecificationByIdQueryOptions,
    SpecificationInterface,
    UpdateValidationArgs,
    ValidationInterface,
} from 'queries/specifications/types';
import { PagingMetadata } from 'types/api';

import { del, get as fetchGet, patch, post, put } from '../utils';

export const applyConfiguration = async ({ id }: { id: number }) =>
    post(`/specifications/${id}/apply-configuration`);

export interface GetAllSpecificationsResponse {
    specifications: SpecificationInterface[];
}

export const getAll = async ({
    state,
}: {
    state?: string[];
}): Promise<GetAllSpecificationsResponse> =>
    state
        ? fetchGet('/specifications', { query: { state } })
        : fetchGet('/specifications');

export const get = ({ id }: GetSpecificationByIdQueryOptions) =>
    fetchGet(`/specifications/${id}`);

export const create = async ({
    schemaId,
    rulesetId,
    versionIncrement,
}: CreateSpecificationArgs) => {
    return post('/specifications', {
        headers: {},
        body: {
            schemaId,
            rulesetId,
            versionIncrement,
        },
    });
};

interface GetValidationsReturnType {
    validations: ValidationInterface[];
    pagingMetadata: PagingMetadata & { filteredTotal: number };
}

export const getValidations = ({
    id,
    filter,
    offset,
    limit,
}: {
    id: number;
    filter: string;
    offset: number;
    limit: number;
}): Promise<GetValidationsReturnType> =>
    fetchGet(`/specifications/${id}/validations`, {
        query: { filter, offset, limit },
    });

export const getDerivedFields = ({
    id,
    filter,
    offset,
    limit,
}: {
    id: number;
    filter: string;
    offset: number;
    limit: number;
}) =>
    fetchGet(`/specifications/${id}/derived-fields`, {
        query: { filter, offset, limit },
    });

export const updateState = async ({
    id,
    stateId,
}: {
    id: number;
    stateId: number;
}) =>
    put(`/specifications/${id}/state`, {
        body: { stateId },
    });

export const deleteRequest = async ({ id }: { id: number }): Promise<void> => {
    return del(`/specifications/${id}`);
};

export interface UpdateValidationResponse {
    specId: number;
    validation: ValidationInterface;
}
export const updateValidation = ({
    specId,
    validationId,
    metadata,
    tolerances,
}: UpdateValidationArgs): Promise<UpdateValidationResponse> =>
    patch(`/specifications/${specId}/validations/${validationId}`, {
        headers: {},
        body: { metadata, tolerances },
    });
