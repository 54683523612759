import {
    del,
    get,
    getFilenameFromContentHeader,
    getRaw,
    post,
    put,
} from 'services/api/utils';
import {
    CollectionReport,
    IrisReportEntry,
    IrisReprocessFilePayload,
    PGRTransfersReport,
} from 'types/collectionReports';

import {
    DownloadableReport,
    GetContinuityPopulationReportResponse,
    GetFrequencyCountReportRequestArgs,
    GetFrequencyCountReportResponse,
    GetIrisReprocessFilesReponse,
    GetNSSReportResponse,
} from './types';

// Misc.
export const getReports = ({ submissionId }: { submissionId: string }) =>
    get(`/collection-reports/${submissionId}/reports`) as Promise<
        CollectionReport[]
    >;

// Frequency Counts
export const getFrequencyCountsReport = ({
    submissionId,
    hideZeroValues,
}: GetFrequencyCountReportRequestArgs) =>
    get(`/collection-reports/frequency-counts/${submissionId}`, {
        query: {
            hideZeroValues,
        },
    }) as Promise<GetFrequencyCountReportResponse>;

// PGR Transfers
export const getPGRTransfersOutReport = ({
    submissionId,
}: {
    submissionId: string;
}) =>
    get(
        `/collection-reports/pgr-transfers-out/summary-data/${submissionId}`,
    ) as Promise<PGRTransfersReport>;

export const getPGRTransfersOutConsentStatus = ({
    submissionId,
}: {
    submissionId: string;
}) =>
    get(
        `/collection-reports/pgr-transfers-out/consent/${submissionId}`,
    ) as Promise<string[]>;

export const getPGRTransfersInReport = ({
    submissionId,
}: {
    submissionId: string;
}) =>
    get(
        `/collection-reports/pgr-transfers-in/summary-data/${submissionId}`,
    ) as Promise<PGRTransfersReport>;

export const getPGRTransfersOutDownload = ({
    submissionId,
    instId,
}: {
    submissionId: string;
    instId: string;
}) =>
    get(
        `/collection-reports/pgr-transfers-out/download-data/${submissionId}/${instId}`,
    ) as Promise<DownloadableReport>;
export const getPGRTransfersInDownload = ({
    submissionId,
    instId,
}: {
    submissionId: string;
    instId: string;
}) =>
    get(
        `/collection-reports/pgr-transfers-in/download-data/${submissionId}/${instId}`,
    ) as Promise<DownloadableReport>;

export const putPGRTransfersIn = ({ submissionId }: { submissionId: string }) =>
    put(
        `/collection-reports/pgr-transfers-in/${submissionId}`,
    ) as Promise<void>;

export const deletePGRTransfersIn = ({
    submissionId,
}: {
    submissionId: string;
}) =>
    del(
        `/collection-reports/pgr-transfers-in/${submissionId}`,
    ) as Promise<void>;

// Continuity Reports
export const getContinuityPopulationReport = ({
    submissionId,
}: {
    submissionId: string;
}) =>
    get(
        `/collection-reports/conpop/${submissionId}`,
    ) as Promise<GetContinuityPopulationReportResponse>;

// Iris
export const getIrisReport = ({ submissionId }: { submissionId: string }) =>
    get(`/collection-reports/iris/${submissionId}`) as Promise<
        IrisReportEntry[]
    >;

export const getIrisReprocessFiles = () =>
    get(
        `/collection-reports/iris/reprocess-files`,
    ) as Promise<GetIrisReprocessFilesReponse>;

export const postIrisReprocessFiles = ({
    files,
}: {
    files: IrisReprocessFilePayload[];
}) =>
    post(`/collection-reports/iris/reprocess-files`, {
        body: files,
    }) as Promise<void>;

// NSS
export const getNSSReport = ({ submissionId }: { submissionId: string }) =>
    get(
        `/collection-reports/nss/${submissionId}`,
    ) as Promise<GetNSSReportResponse>;

// CCA
// Note: special case for this handler is it is housed within CredibilityAPI for excel code sharing
export const getCCAReportXlsx = async ({
    submissionId,
}: {
    submissionId: string;
}) => {
    const res = await getRaw(
        `/credibilityapi/collection-report/cca/${submissionId}/xlsx`,
        {
            headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        },
    );

    const header = res.headers.get('Content-Disposition');
    const fileName = getFilenameFromContentHeader(header);
    const contents = URL.createObjectURL(await res.blob());
    return { contents, fileName };
};

// Comparison Report
export const getComparisonReport = ({
    submissionId,
}: {
    submissionId: string;
}) =>
    get(
        `/collection-reports/comparison/${submissionId}`,
    ) as Promise<DownloadableReport>;
