import { useMutation, useQueryClient } from '@tanstack/react-query';
import { submissions } from 'services/api';
import { LatestSubmission } from 'types/submission';

interface UseApproveSubmissionArgs {
    latestSubmission: LatestSubmission;
}
export default function useApproveSubmission({
    latestSubmission,
}: UseApproveSubmissionArgs) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () =>
            submissions.updateSubmissionState({
                action: 'approve',
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                statusId: latestSubmission.nextApprovalState!.id, // Not null assertion is safe here because we are only calling this hook when nextApprovalState is defined
                submissionId: latestSubmission.uuid,
            }),
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['submissions', 'provider-statuses'],
            });
        },
    });
}
