import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import QualityRule from 'components/QualityRule/QualityRule';
import useTableParams from 'hooks/useTableParams/useTableParams';
import useRegulators from 'queries/regulator/useRegulators';
import useValidationDetails from 'queries/submissions/useValidationDetails';
import {
    getValidationDetailsCsv,
    getValidationDetailsUri,
} from 'services/api/submissions';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import { Regulator } from 'types/regulator';

import {
    requestBlobDownload,
    requestQualityRuleFailuresDownload,
} from '../../utils';

const getRegulator = (regulators: Regulator[], code: string) => {
    return regulators.find(regulator => regulator.regulatorCode === code);
};

const OvtQualityRule = () => {
    const { rule, submissionId, regulatorCode } = useParams() as {
        rule: string;
        submissionId: string;
        regulatorCode: string;
    };

    const { instId }: { instId: string } = useOutletContext();

    const [isDownloading, setIsDownloading] = React.useState(false);
    const { data: regulators, status: regulatorsStatus } = useRegulators();

    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
    };

    const tableId = TABLE_PARAMS_IDS.ONLINE_VALIDATION_QUALITYRULE;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { limit, offset } = tableParams;

    const { setLimit, setOffset } = tableParamsMethods;

    const { data: validationDetails, status } = useValidationDetails({
        submissionId,
        validationId: rule,
        instId,
        regulatorCode,
        offset: offset,
        limit: limit,
        ovt: true,
    });

    function handleChangeRowsPerPage(event: {
        target: { value: string | number };
    }) {
        setLimit(+event.target.value);
        setOffset(0);
    }

    function handleChangePage(_: unknown, newPage: number) {
        setOffset(newPage);
    }

    const handleClickDownloadCsv = async () => {
        if (submissionId) {
            setIsDownloading(true);
            if (
                validationDetails &&
                validationDetails.pagingMetadata?.totalResultsCount &&
                validationDetails.pagingMetadata?.totalResultsCount > 1000
            ) {
                const uri = await getValidationDetailsUri({
                    submissionId,
                    validationId: rule,
                    instId,
                    regulatorCode,
                    ovt: true,
                });

                await requestQualityRuleFailuresDownload(uri);
            } else {
                const csv = await getValidationDetailsCsv({
                    submissionId,
                    validationId: rule,
                    instId,
                    regulatorCode,
                    ovt: true,
                });

                // downlaod csv file
                await requestBlobDownload(
                    csv,
                    `QualityRuleReport.${submissionId.toLowerCase()}.${rule}.${new Date().toISOString()}.csv`,
                );
            }
            setIsDownloading(false);
        }
    };

    return (
        <>
            {(status === 'pending' || regulatorsStatus === 'pending') && (
                <div>Loading...</div>
            )}
            {status === 'success' && regulatorsStatus === 'success' && (
                <QualityRule
                    isDownloading={isDownloading}
                    rule={rule}
                    regulator={getRegulator(regulators, regulatorCode)}
                    results={validationDetails.records}
                    validation={validationDetails.validation}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleClickDownloadCsv={handleClickDownloadCsv}
                    pageData={validationDetails.pagingMetadata}
                    offset={offset}
                    limit={limit}
                />
            )}
            {(status === 'error' || regulatorsStatus === 'error') && (
                <div>Error</div>
            )}
        </>
    );
};

export default OvtQualityRule;
