import React, { useState } from 'react';
import GhostButton from 'components/Button/GhostButton';
import { Provider } from 'types/provider';

import { SubmissionHydrated } from '../utils';

import AwaitingActionsFilter from './AwaitingActionsFilter';
import ProviderFilter from './ProviderFilter';
import RegulatorFilter from './RegulatorFilter';
import StateFilter from './StateFilter';

import styles from './filters.module.scss';

interface FiltersProps {
    providers: Provider[];
    submissions: SubmissionHydrated[];
    setSelectedProviders: (value: string[]) => void;
    setSelectedStatuses: (value: string[]) => void;
    selectedProviders: string[];
    selectedStatuses: string[];
    selectedRegulators: string[];
    setSelectedRegulators: (value: string[]) => void;
    selectedAwaitingAction?: string[];
    setSelectedAwaitingAction?: (value: string[]) => void;
    isOvt?: boolean;
}

export default function Filters({
    providers,
    submissions,
    setSelectedProviders,
    setSelectedStatuses,
    selectedProviders,
    selectedStatuses,
    selectedRegulators,
    setSelectedRegulators,
    selectedAwaitingAction,
    setSelectedAwaitingAction,
    isOvt = false,
}: FiltersProps) {
    const [providerInput, setProviderInput] = useState('');
    const [stateInput, setStateInput] = useState('');
    const [regulatorInput, setRegulatorInput] = useState('');
    const [awaitingActionInput, setAwaitingActionInput] = useState('');

    const handleClearAllFilters = () => {
        if (!isOvt && setSelectedAwaitingAction) setSelectedAwaitingAction([]);
        setSelectedProviders([]);
        setSelectedRegulators([]);
        setSelectedStatuses([]);
        setProviderInput('');
        setStateInput('');
        setRegulatorInput('');
        setAwaitingActionInput('');
    };

    const handleProviderChange = (_: React.SyntheticEvent, value: string[]) => {
        setSelectedProviders(value);
    };

    const handleStateChange = (_: React.SyntheticEvent, value: string[]) => {
        setSelectedStatuses(value);
    };

    const handleRegulatorChange = (
        _: React.SyntheticEvent,
        value: string[],
    ) => {
        setSelectedRegulators(value);
    };

    const handleAwaitingActionChange = (
        _: React.SyntheticEvent,
        value: string[],
    ) => {
        if (!isOvt && setSelectedAwaitingAction) {
            setSelectedAwaitingAction(value);
        }
    };

    return (
        <div className={styles.filters}>
            <ProviderFilter
                providers={providers}
                onChange={handleProviderChange}
                value={selectedProviders}
                inputValue={providerInput}
                onInputChange={v => setProviderInput(v)}
            />
            <StateFilter
                submissions={submissions}
                onChange={handleStateChange}
                value={selectedStatuses}
                inputValue={stateInput}
                onInputChange={v => setStateInput(v)}
            />
            <RegulatorFilter
                value={selectedRegulators}
                submissions={submissions}
                onChange={handleRegulatorChange}
                inputValue={regulatorInput}
                onInputChange={v => setRegulatorInput(v)}
            />
            {!isOvt && Array.isArray(selectedAwaitingAction) && (
                <AwaitingActionsFilter
                    value={selectedAwaitingAction}
                    submissions={submissions}
                    onChange={handleAwaitingActionChange}
                    inputValue={awaitingActionInput}
                    onInputChange={v => setAwaitingActionInput(v)}
                />
            )}
            <GhostButton onClick={handleClearAllFilters}>
                Clear all filters
            </GhostButton>
        </div>
    );
}
