import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { useUserContext } from 'components/User/useUserContext';
import { Submission } from 'types/submission';
import { HdpMessage } from 'types/websocket';

import { getStepRoutes } from '../../utils';

import stepFilters from './StepFilters/index';
import Step from './Step';
import StepperProvider from './StepperContext';
import { StepName } from './types';

import styles from './stepper.module.scss';

export type StepperProps = {
    submissionId: string;
    collectionId: number;
    steps: StepName[];
    ovt?: boolean;
    submissionData: Submission;
    children?: ReactNode;
    latestMessage?: HdpMessage | null;
};

const Stepper = (props: StepperProps) => {
    const {
        submissionId,
        collectionId,
        steps,
        latestMessage,
        ovt = false,
        children,
    } = props;
    const { isStatutoryCustomer, isHesa, isAdmin, isAnalyst } =
        useUserContext();

    const params = useParams();

    const getStepPaths = () => {
        const routes = getStepRoutes(ovt, {
            submissionId: submissionId,
            collectionId,
            ...params,
        });

        const paths: Record<string, string> = {};

        Object.entries(routes).forEach(([step, route]) =>
            isAdmin || isAnalyst || isHesa
                ? (paths[step] = route.adminPath)
                : (paths[step] = route.path),
        );

        return paths;
    };

    const stepPaths = getStepPaths();

    return (
        <StepperProvider
            {...props}
            isStatutoryCustomer={isStatutoryCustomer}
            ovt={ovt}
            paths={stepPaths}
            latestMessage={latestMessage}
            key={`stepper-ctx-${submissionId}`}
        >
            <nav data-test-id="stepper" className={styles.stepper}>
                <ol className={styles.list}>
                    {steps.map(stepName => (
                        <Step
                            key={`step-${stepName}-${submissionId}`}
                            label={stepName}
                            stepFilters={stepFilters[stepName]}
                        />
                    ))}
                </ol>
            </nav>
            {children}
        </StepperProvider>
    );
};

Stepper.displayName = 'Stepper';

export default Stepper;
