import React, { useCallback, useEffect } from 'react';
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import { getFailureSummaryTable } from 'services/api/submissions';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { SubmissionFailureSummary } from 'types/submission';

import styles from './summary.module.scss';

interface SummaryProps {
    submissionId: string;
    instId: string;
    summaryData?: SubmissionFailureSummary;
    setSummaryData: (data: SubmissionFailureSummary) => void;
}

const Summary = ({
    submissionId,
    instId,
    summaryData,
    setSummaryData,
}: SummaryProps) => {
    const getRegulatorFailures = useCallback(
        async (submissionId: string, instId: string) => {
            const results = await getFailureSummaryTable({
                submissionId,
                instId,
            });
            setSummaryData(results);
        },
        [setSummaryData],
    );
    useEffect(() => {
        const fetchData = async () => {
            await getRegulatorFailures(submissionId, instId);
        };
        fetchData();
    }, [instId, submissionId, getRegulatorFailures]);

    const SummaryTable = () => {
        return (
            <Table data-test-id={formatTestId('table', 'summary')}>
                <thead>
                    <TableRow className={styles.header}>
                        {summaryData?.header.map((cell, index) => {
                            return (
                                <TableCell
                                    key={index}
                                    className={styles.bold}
                                    component={cell ? 'th' : 'td'}
                                    scope={cell ? 'col' : undefined}
                                >
                                    {cell}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </thead>
                <TableBody>
                    <TableRow className={styles.row}>
                        {summaryData?.insideTolerance.map((cell, index) => (
                            <TableCell
                                key={index}
                                component={index === 0 ? 'th' : 'td'}
                                scope={index === 0 ? 'row' : undefined}
                                className={index === 0 ? styles.boldish : ''}
                            >
                                {cell}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow className={styles.row}>
                        {summaryData?.outsideTolerance.map((cell, index) => (
                            <TableCell
                                key={index}
                                component={index === 0 ? 'th' : 'td'}
                                scope={index === 0 ? 'row' : undefined}
                                className={
                                    index !== 0 && cell !== ''
                                        ? styles.red
                                        : styles.boldish
                                }
                            >
                                {cell}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    return (
        <div>
            {submissionId && summaryData ? (
                <div>
                    <SummaryTable />
                </div>
            ) : (
                <CircularProgress aria-label="Circular loading animation" />
            )}
        </div>
    );
};

export default Summary;
