import { useEffect, useMemo, useReducer } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';

interface TableParamsReducerAction<T> {
    type: 'set' | string;
    key: keyof T | string;
    value: T[keyof T];
}

interface TableParams<T> {
    values: T;
    methods: {
        [Property in keyof T as `set${Capitalize<string & Property>}`]: (
            value: T[Property],
        ) => void;
    };
}

function isObject(obj: unknown) {
    return obj !== undefined && obj !== null && obj.constructor === Object;
}

/* 
    Search parameters are stored as strings in the URL, but the app needs to use them as their original types.
    This function converts the search parameter to its original type.
    */
export const convertSearchParamToType = <T extends object>(
    parameterName: keyof T,
    value: string,
    initialValues: T,
) => {
    if (typeof initialValues[parameterName] === 'number') {
        return Number(value);
    } else if (typeof initialValues[parameterName] === 'boolean') {
        return value === 'true';
    } else if (
        isObject(initialValues[parameterName]) ||
        Array.isArray(initialValues[parameterName])
    ) {
        return JSON.parse(value);
    } else if (initialValues[parameterName] instanceof Set) {
        return new Set(value.split(','));
    } else {
        return value;
    }
};

function reducer<T extends object>(
    state: T,
    action: TableParamsReducerAction<T>,
) {
    if (action.type === 'set') {
        return {
            ...state,
            [action.key]: action.value,
        };
    }

    return state;
}

/**
 * Custom hook for managing table parameters.
 *
 * @param {string} id - The ID of the table.
 * @param {object} initialValues - The initial values for the table parameters.
 * @returns {TableParams} An object containing the values and methods for managing table parameters.
 */
export default function useTableParams<T extends object>(
    id: string,
    initialValues: T,
): TableParams<T> {
    const [searchParams, setSearchParams] = useSearchParams();

    const setSearchParameterWithId = (
        parameterName: keyof T,
        rawValue: T[keyof T],
        id: string,
        searchParameters: URLSearchParams,
    ) => {
        let value: string;
        // JSON objects need to be stringified before being set as a search parameter
        if (isObject(initialValues[parameterName]) || Array.isArray(rawValue)) {
            value = JSON.stringify(rawValue);
        } else if (initialValues[parameterName] instanceof Set) {
            value =
                rawValue instanceof Set
                    ? Array.from(rawValue).join(',')
                    : String(rawValue);
        } else {
            value = String(rawValue);
        }

        searchParameters.set(`${parameterName as string}${id}`, value);
        return searchParameters;
    };

    const getSearchParameterWithId = (parameterName: keyof T, id: string) => {
        const rawValue = searchParams.get(`${parameterName as string}${id}`);
        if (rawValue === null) {
            // searchParams.get returns null if the parameter is not found
            // our initialValues may be `undefined` or false, so we return null explicitly
            // for the case where the parameter is not found
            return null;
        }
        const value = convertSearchParamToType(
            parameterName,
            rawValue,
            initialValues,
        );
        return value;
    };

    const init = (initialValues: T): T => {
        return Object.keys(initialValues).reduce((acc, key) => {
            return {
                ...acc,
                [key]:
                    getSearchParameterWithId(key as keyof T, id) !== null // searchParameters.get returns null if the parameter is not found
                        ? getSearchParameterWithId(key as keyof T, id)
                        : initialValues[key as keyof T],
            };
        }, {}) as T;
    };

    const [values, dispatch] = useReducer(reducer<T>, initialValues, init);

    // whenever values changes, update the query string
    useEffect(() => {
        const params = createSearchParams(searchParams);
        // for each key in values, set the search parameter
        Object.keys(values).forEach(key => {
            // if the value of the parameter is the same as the initial value, remove it from the search params
            if (values[key as keyof T] === initialValues[key as keyof T]) {
                params.delete(`${key}${id}`);
            } else {
                setSearchParameterWithId(
                    key as keyof T,
                    values[key as keyof T],
                    id,
                    params,
                );
            }
        });
        setSearchParams(params, { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const stateVars = useMemo(() => {
        return Object.keys(initialValues).map(key => ({
            key,
            stateVar: initialValues[key as keyof T],
            setStateVar: (value: T[keyof T]) =>
                dispatch({ key, value, type: 'set' }),
        }));
    }, [initialValues]);

    const methods = useMemo(() => {
        return stateVars.reduce((acc, { key, setStateVar }) => {
            return {
                ...acc,
                [`set${key.charAt(0).toUpperCase()}${key.slice(1)}`]:
                    setStateVar,
            };
        }, {}) as TableParams<T>['methods'];
    }, [stateVars]);

    return { values, methods };
}
