import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Typography } from '@mui/material';
import { SubmissionOutletContextValue } from 'pages/Collections/Submission/types';

import Results from './Results';

const Reports = () => {
    const { data, collection, submissionId, setPageTitle } =
        useOutletContext<SubmissionOutletContextValue>();

    useEffect(() => {
        setPageTitle('Quality Assurance');
    }, [setPageTitle]);

    return data && data.fileName && submissionId ? (
        <React.Fragment>
            <h3>{data.fileName}</h3>
            <Results
                data={data}
                collection={collection}
                submissionId={submissionId}
            />
        </React.Fragment>
    ) : (
        <React.Fragment>
            <Typography>
                You have yet to upload a file. Please upload one on the upload
                step.
            </Typography>
        </React.Fragment>
    );
};

export default Reports;
