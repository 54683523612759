import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import { SubmissionHydrated } from 'pages/Monitoring/SubmissionSummary/utils';

import NotStartedSubmissionSummaryTableRow from './NotStartedSubmissionSummaryTableRow';
import OvtSubmissionSummaryTableRow from './OvtSubmissionSummaryTableRow';

import styles from './ovtSubmissionSummaryTable.module.scss';

interface OvtSubmissionSummaryTableBodyProps {
    submissions: SubmissionHydrated[];
    pagedSubmissions: SubmissionHydrated[];
}

export default function OvtSubmissionSummaryTableBody({
    submissions,
    pagedSubmissions,
}: OvtSubmissionSummaryTableBodyProps) {
    if (submissions.length === 0) {
        return (
            <TableBody>
                <TableRow className={styles.row}>
                    <TableCell align={'center'} colSpan={9}>
                        <Box p={1}>
                            No submissions found that match your criteria
                        </Box>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    }

    const renderNormalRow = (
        submission: SubmissionHydrated,
        index: number,
        className: string,
    ) => (
        <OvtSubmissionSummaryTableRow
            className={className}
            key={`ovt-row-${index}`}
            latestSubmission={submission.latestSubmission}
            provider={submission.provider}
            collection={submission.collection}
        />
    );

    const renderEmptyRow = (
        submission: SubmissionHydrated,
        index: number,
        className: string,
    ) => (
        <NotStartedSubmissionSummaryTableRow
            className={className}
            key={`ovt-row-${index}`}
            provider={submission.provider}
        />
    );

    const renderRow = (submission: SubmissionHydrated, index: number) => {
        const className = styles.row;
        return submission.latestSubmission
            ? renderNormalRow(submission, index, className)
            : renderEmptyRow(submission, index, className);
    };

    return (
        <TableBody className="hdp-override--body-cell">
            {pagedSubmissions.map(renderRow)}
        </TableBody>
    );
}
