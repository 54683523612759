import { CircularProgress } from '@mui/material';
import { useUserContext } from 'components';
import { REGULATOR_OFS } from 'constants/constants';
import useAdditionalReports from 'queries/additional-reports/useAdditionalReports';

import AdditionalReportTable from './AdditionalReportsTable';

import styles from './additionalReports.module.scss';

const AdditionalReports = ({ submissionId }: { submissionId: string }) => {
    const {
        isHesa,
        isProvider,
        isStatutoryCustomer,
        activeOrganisation,
        roles,
    } = useUserContext();

    const isStatutoryCustomerUserWithAccess =
        isStatutoryCustomer && activeOrganisation.id === REGULATOR_OFS;

    const isProviderUserWithAccess =
        isProvider &&
        !!(roles.includes('sign-off') || roles.includes('submitter'));

    const { data, isLoading, isError } = useAdditionalReports({
        submissionId,
    });

    const ProgressBar = () => (
        <div className={styles.loading}>
            <CircularProgress aria-label="Circular loading animation" />
        </div>
    );

    const Failure = () => (
        <div className={styles.loading}>
            No results found for this submission.
        </div>
    );

    if (isError) return <Failure />;
    if (isLoading || !data) return <ProgressBar />;

    return (
        <AdditionalReportTable
            data={data}
            isUserAbleToAccessNSSReport={
                isHesa ||
                isStatutoryCustomerUserWithAccess ||
                isProviderUserWithAccess
            }
        />
    );
};

export default AdditionalReports;
