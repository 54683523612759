import React, { useCallback, useEffect, useState } from 'react';
import {
    generatePath,
    Link,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { ArrowDropDownSharp, Search } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
    Button,
    CircularProgress,
    DialogContentText,
    FormControl,
    FormControlLabel,
    IconButton,
    Menu,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
} from '@mui/material';
import { RestrictAccess, useUserContext } from 'components';
import Accordion from 'components/Accordion/Accordion';
import AccordionGroup from 'components/AccordionGroup/AccordionGroup';
import BannerBox from 'components/BannerBox/BannerBox';
import { DateTime } from 'luxon';
import { requestBlobDownload } from 'pages/Collections/OnlineValidation/utils';
import useProvider from 'queries/provider/useProvider';
import {
    collections as collectionsApi,
    credibility as credibilityApi,
    files as filesApi,
    submissions as submissionsApi,
} from 'services/api';
import {
    getCompareValidations,
    getValidations,
    getValidationsCsv,
} from 'services/api/submissions';
import {
    GetCompareSubmissionsValidationsResponse,
    ValidationsSortKey,
} from 'services/api/submissions/types';
import ConfirmationPopup from 'src/components/ConfirmationPopup/ConfirmationPopup';
import InformationPopup from 'src/components/InformationPopup/InformationPopup';
import { AL_CREDIBILITY_REQUESTED } from 'src/constants/activityLogTypeIds';
import {
    ACCORDION_IDS,
    PATHS,
    TABLE_PARAMS_IDS,
} from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';
import AdditionalReports from 'src/pages/Collections/Submission/Steps/Reports/AdditionalReports/AdditionalReports';
import CredibilityReports from 'src/pages/Collections/Submission/Steps/Reports/Credibility/CredibilityReports/CredibilityReports';
import QualityRulesCompare from 'src/pages/Collections/Submission/Steps/Reports/QualityRules/QualityRulesCompare';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { PagingMetadata } from 'types/api';
import { Collection } from 'types/collection';
import { CredibilityPreviousSubmission } from 'types/credibility';
import {
    Submission,
    SubmissionFailureSummary,
    SubmissionValidation,
} from 'types/submission';
import { v4 as uuid } from 'uuid';

import { SubmissionOutletContextValue } from '../../types';
import { requestAllQualityRuleFailuresDownload } from '../../utils';
import { useStepper } from '../Stepper/StepperContext';

import QualityRules from './QualityRules/QualityRules';
import Summary from './Summary/Summary';

import styles from './results.module.scss';

const accordions = {
    summary: {
        title: 'Summary',
        text: 'Overview of rules failures inside and outside of tolerance',
        searchParam: ACCORDION_IDS.SUBMISSION.SUMMARY,
    },
    quality: {
        title: 'Quality Report',
        text: 'Quality report failures in data submission',
        searchParam: ACCORDION_IDS.SUBMISSION.QUALITY_REPORT,
    },
    credibility: {
        title: 'Credibility Reports',
        text: 'Management information tables for quality assurance',
        searchParam: ACCORDION_IDS.SUBMISSION.CREDIBILITY_REPORTS,
    },
    additional: {
        title: 'Additional Report',
        text: 'Further available reports for this collection',
        searchParam: ACCORDION_IDS.SUBMISSION.ADDITIONAL_REPORTS,
    },
};

// Check if an object has any properties. Returns true if the object has keys, false if it is empty.
const checkIfObjectHasKeys = <AnyObject extends object>(
    obj: AnyObject | undefined,
): boolean => {
    if (!obj) return false;
    return Object.keys(obj).length !== 0;
};

interface ResultsProps {
    data: Submission;
    submissionId: string;
    collectionId: string;
    collection?: Collection;
}

const Results = ({
    data,
    submissionId,
    collectionId,
    collection,
}: ResultsProps) => {
    const { canUploadNewSubmission } =
        useOutletContext<SubmissionOutletContextValue>();

    const { reference = '' } = useParams();

    // HDP-3405 - we are hardcoding the display banner for 23506 because some reports are unavailable.
    const additionalReportsUnavailable = collection?.reference === '23056';

    const history = useNavigate();
    const { isAdmin } = useUserContext();
    const [pagingData, setPagingData] = useState<PagingMetadata>({
        totalResultsCount: 0,
        limit: 10,
        offset: 0,
    });
    const [pagingCompareData, setPagingCompareData] = useState<PagingMetadata>({
        totalResultsCount: 0,
        limit: 10,
        offset: 0,
    });
    const [validationRows, setValidationRows] = useState<
        SubmissionValidation[]
    >([]);
    const [showDownloadDialog, setShowDownloadDialog] = useState(false);
    const [
        showAllQualityRuleFailuresDownloadDialog,
        setShowAllQualityRuleFailuresDownloadDialog,
    ] = useState(false);

    const [hasErroredFetching, setHasErroredFetching] = useState(false);

    const { activeOrganisation } = useUserContext();
    const [previousSubmissions, setPreviousSubmissions] = useState<
        CredibilityPreviousSubmission[]
    >([]);
    const [allQualityResultsFileExists, setAllQualityResultsFileExists] =
        useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null,
    );
    const [compareValidations, setCompareValidations] = React.useState<
        GetCompareSubmissionsValidationsResponse | undefined
    >(undefined);
    const [noCompareResultsFound, setNoCompareResultsFound] =
        React.useState(false);
    const [noResultsFound, setNoResultsFound] = React.useState(false);

    const [noMatchingResults, setNoMatchingResults] = useState(false);
    const [searchedEmptyResults, setSearchedEmptyResults] = useState(false);
    const [summaryData, setSummaryData] = useState<
        SubmissionFailureSummary | undefined
    >(undefined);
    const currentProvider = useProvider({ instId: data.provider?.instId });
    const [collectionExpired, setcollectionExpired] = useState(false);
    const { navigateToStep } = useStepper();

    const DEFAULT_TABLE_PARAMETERS = {
        searchTerm: '',
        limit: 10,
        offset: 0,
        isCompare: false,
        hideUnchangedValues: false,
        sortBy: 'FAILURE' as
            | 'FAILURE'
            | 'POPULATION'
            | 'TOLERANCE'
            | 'STATUS'
            | 'ID',
        sortOrder: 'desc' as 'asc' | 'desc',
        selectedStatus: 1 as 0 | 1,
        showCompareComponent: false,
        selectedPreviousSubmission: {} as
            | CredibilityPreviousSubmission
            | undefined,
    };

    const tableId = TABLE_PARAMS_IDS.QUALITY_RULES;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const {
        searchTerm,
        limit,
        offset,
        isCompare,
        hideUnchangedValues,
        sortBy,
        sortOrder,
        selectedStatus,
        selectedPreviousSubmission,
    } = tableParams;

    const {
        setSearchTerm,
        setLimit,
        setOffset,
        setIsCompare,
        setHideUnchangedValues,
        setSortBy,
        setSortOrder,
        setSelectedStatus,
        setSelectedPreviousSubmission,
    } = tableParamsMethods;

    if (hasErroredFetching) {
        throw new Error('Could not fetch submission validations');
    }

    const getAllQualityResultsFileExists = async (submissionUuid: string) => {
        try {
            const allQualityResultsFileExistsResponse =
                await filesApi.getAllQualityRuleFailuresExists(submissionUuid);

            setAllQualityResultsFileExists(
                !!allQualityResultsFileExistsResponse.result.fileExists,
            );
        } catch (e) {
            setAllQualityResultsFileExists(false);
        }
    };

    const getPreviousSubmissions = useCallback(
        async (submissionId: string, collectionId: number, instId: string) => {
            try {
                const previousSubmissions =
                    await credibilityApi.getPreviousSubmissions({
                        submissionId,
                        collectionId,
                        instId,
                    });
                setPreviousSubmissions(previousSubmissions);
            } catch (e) {
                setHasErroredFetching(true);
            }
        },
        [],
    );

    const handleClearSearch = () => {
        setSearchTerm('');
        handleSearchSubmit('');
    };

    const handleChangeStatus = (event: SelectChangeEvent<0 | 1>) => {
        setOffset(0);
        setSelectedStatus(Number(event.target.value) as 0 | 1);
        if (
            selectedPreviousSubmission !== undefined &&
            checkIfObjectHasKeys(selectedPreviousSubmission)
        ) {
            setCompareValidations(undefined);
            getValidationCompareData(
                data.uuid,
                selectedPreviousSubmission?.submissionId,
                event.target.value as 0 | 1,
                sortBy,
                sortOrder,
                0,
                limit,
                searchTerm,
                hideUnchangedValues ? 1 : 0,
            );
        } else {
            setValidationRows([]);
            loadValidationData(
                data.uuid,
                event.target.value as 0 | 1,
                sortBy,
                sortOrder,
                0,
                limit,
                searchTerm,
                summaryData,
            );
        }
    };

    const handleSearchSubmit = (updatedSearchTerm: string | null = null) => {
        setOffset(0);
        if (
            selectedPreviousSubmission !== undefined &&
            checkIfObjectHasKeys(selectedPreviousSubmission)
        ) {
            setCompareValidations(undefined);
            getValidationCompareData(
                data.uuid,
                selectedPreviousSubmission?.submissionId,
                selectedStatus,
                sortBy,
                sortOrder,
                0,
                limit,
                updatedSearchTerm !== null ? updatedSearchTerm : searchTerm,
                hideUnchangedValues ? 1 : 0,
            );
        } else {
            setValidationRows([]);
            loadValidationData(
                data.uuid,
                selectedStatus,
                sortBy,
                sortOrder,
                0,
                limit,
                updatedSearchTerm !== null ? updatedSearchTerm : searchTerm,
                summaryData,
            );
        }
    };

    const handleEnterKey = (event: { keyCode: number }) => {
        if (event.keyCode === 13) handleSearchSubmit();
    };
    const handleUpdateSearchTerm = (event: { target: { value: string } }) =>
        setSearchTerm(event.target.value);

    const toggleHideUnchanged = (event: { target: { checked: boolean } }) => {
        setOffset(0);
        setHideUnchangedValues(event.target.checked);
        setCompareValidations(undefined);

        if (
            selectedPreviousSubmission !== undefined &&
            checkIfObjectHasKeys(selectedPreviousSubmission)
        ) {
            getValidationCompareData(
                data.uuid,
                selectedPreviousSubmission.submissionId,
                selectedStatus,
                sortBy,
                sortOrder,
                0,
                limit,
                searchTerm,
                event.target.checked ? 1 : 0,
            );
        }
    };

    const handleSortByClicked = (
        sortByColumn: 'POPULATION' | 'FAILURE' | 'TOLERANCE' | 'STATUS' | 'ID',
        sortDirection: 'asc' | 'desc',
        page: number,
    ) => {
        setSortBy(sortByColumn);
        setSortOrder(sortDirection);
        setOffset(page);
        setValidationRows([]);
        loadValidationData(
            data.uuid,
            selectedStatus,
            sortByColumn,
            sortDirection,
            page,
            limit,
            searchTerm,
        );
    };

    const handleCompareSortByClicked = (
        sortByColumn: 'POPULATION' | 'FAILURE' | 'TOLERANCE' | 'STATUS' | 'ID',
        sortDirection: 'asc' | 'desc',
        page: number,
    ) => {
        setSortBy(sortByColumn);
        setSortOrder(sortDirection);
        setOffset(page);
        setCompareValidations(undefined);

        if (
            selectedPreviousSubmission !== undefined &&
            checkIfObjectHasKeys(selectedPreviousSubmission)
        ) {
            getValidationCompareData(
                data.uuid,
                selectedPreviousSubmission?.submissionId,
                selectedStatus,
                sortByColumn,
                sortDirection,
                page,
                limit,
                searchTerm,
                hideUnchangedValues ? 1 : 0,
            );
        }
    };

    const handlePageClicked = (page: number, rowsPage: number) => {
        setOffset(page);
        setLimit(rowsPage);
        setValidationRows([]);
        loadValidationData(
            data.uuid,
            selectedStatus,
            sortBy,
            sortOrder,
            page,
            rowsPage,
            searchTerm,
        );
    };

    const handleComparePageClicked = (page: number, rowsPage: number) => {
        setOffset(page);
        setLimit(rowsPage);
        setCompareValidations(undefined);

        if (
            selectedPreviousSubmission !== undefined &&
            checkIfObjectHasKeys(selectedPreviousSubmission)
        ) {
            getValidationCompareData(
                data.uuid,
                selectedPreviousSubmission.submissionId,
                selectedStatus,
                sortBy,
                sortOrder,
                page,
                rowsPage,
                searchTerm,
                hideUnchangedValues ? 1 : 0,
            );
        }
    };

    const newSubmission = () => {
        const newSubmissionRoute = generatePath(
            isAdmin ? PATHS.ADMIN_UPLOAD : PATHS.UPLOAD,
            {
                collectionId: collectionId,
                submissionId: uuid().toUpperCase(),
                instId: data.provider.instId,
                reference: collection?.reference || reference,
            },
        );
        history(newSubmissionRoute);
    };

    const openDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeDropDown = async (
        selectedSubmission: CredibilityPreviousSubmission,
    ) => {
        setAnchorEl(null);
        if (selectedSubmission && selectedSubmission.submissionId) {
            setSelectedPreviousSubmission(selectedSubmission);
            setValidationRows([]);
            setOffset(0);
            setLimit(10);
            setHideUnchangedValues(false);

            await getValidationCompareData(
                data.uuid,
                selectedSubmission.submissionId,
                selectedStatus,
                sortBy,
                sortOrder,
                0,
                10,
                searchTerm,
                0,
            );
        }
    };

    const getSubmissionDisplay = (
        submission: CredibilityPreviousSubmission,
    ) => (
        <React.Fragment>
            <span className={styles.fakeDrodDownItemID}>
                {submission.submissionId.substring(
                    submission.submissionId.length - 9,
                )}
            </span>{' '}
            <span className={styles.fakeDrodDownItemDate}>
                {DateTime.fromISO(submission.uploadedDate).toFormat(
                    'dd/MM/yyyy HH:mm',
                )}
            </span>
        </React.Fragment>
    );

    const getValidationCompareData = useCallback(
        async (
            submissionId: string,
            previousSubmissionId: string,
            status: 0 | 1,
            sortBy: ValidationsSortKey,
            sortOrder: 'desc' | 'asc',
            offset: number,
            limit: number,
            searchTerm = '',
            hideUnchangedValues: 0 | 1,
        ) => {
            try {
                setNoCompareResultsFound(false);
                const validationCompareData = await getCompareValidations({
                    submissionId,
                    previousSubmissionId,
                    instId: data.provider
                        ? data.provider.instId
                        : activeOrganisation.id,
                    status,
                    searchTerm,
                    sortBy,
                    sortOrder,
                    offset,
                    limit,
                    hideUnchangedValues,
                });

                setCompareValidations(validationCompareData);
                setPagingCompareData(validationCompareData.pagingMetadata);
                if (!validationCompareData?.records?.length) {
                    setNoCompareResultsFound(true);
                }
            } catch (e) {
                setHasErroredFetching(true);
            }
        },
        [activeOrganisation, data.provider],
    );

    const loadValidationData = useCallback(
        async (
            _uuid: string,
            _selectedStatus: 0 | 1,
            _sortBy: 'POPULATION' | 'FAILURE' | 'TOLERANCE' | 'STATUS' | 'ID',
            _sortOrder: 'asc' | 'desc',
            _offset: number,
            _limit: number,
            _searchTerm = '',
            _summaryData: SubmissionFailureSummary | undefined = undefined,
        ) => {
            const fetchValidations = async () => {
                try {
                    setNoMatchingResults(false);
                    setSearchedEmptyResults(false);
                    setNoResultsFound(false);
                    const result = await getValidations({
                        submissionId: _uuid,
                        instId: data.provider
                            ? data.provider.instId
                            : activeOrganisation.id,
                        status: _selectedStatus,
                        searchTerm: _searchTerm,
                        sortBy: _sortBy,
                        sortOrder: _sortOrder,
                        offset: _offset,
                        limit: _limit,
                    });
                    return result;
                } catch {
                    setHasErroredFetching(true);
                }
            };

            const result = await fetchValidations();

            let primaryResults = result?.records?.filter(
                x => x.IsPrimary === true,
            );
            let specialistResults = result?.records
                ?.filter(x => x.IsPrimary === false)
                .sort((a, b) => (a.RegulatorCode > b.RegulatorCode ? 1 : -1));

            if (primaryResults) {
                primaryResults = primaryResults.map(result => {
                    if (specialistResults) {
                        // Get primary specialist results
                        const primarySpecialistResult =
                            specialistResults.filter(
                                special => special.Code === result.Code,
                            );
                        // Strip sub specials from man specials list
                        specialistResults = specialistResults.filter(
                            special => special.Code !== result.Code,
                        );

                        return {
                            ...result,
                            specialistRegulators: primarySpecialistResult,
                        };
                    }
                    return result;
                });
                primaryResults = [
                    ...(primaryResults || []),
                    ...(specialistResults || []),
                ];
                setValidationRows(primaryResults);
                setPagingData(
                    result?.pagingMetadata ?? {
                        totalResultsCount: 0,
                        limit: limit,
                        offset: offset,
                    },
                );
                if (!result?.records?.length) {
                    setNoResultsFound(true);
                    if (_searchTerm !== '') {
                        const statusName =
                            _selectedStatus === 1
                                ? 'outsideTolerance'
                                : 'insideTolerance';
                        const [, totalIssues] = _summaryData
                            ? _summaryData[statusName]
                            : [];
                        if (parseInt(totalIssues) === 0) {
                            setNoMatchingResults(true);
                        } else {
                            setSearchedEmptyResults(true);
                        }
                    }
                }
            }
        },
        [activeOrganisation, data.provider, limit, offset],
    );

    const handleClickDownloadCsv = useCallback(async () => {
        if (data.uuid) {
            const csv = await getValidationsCsv({
                submissionId: data.uuid,
                instId: data.provider
                    ? data.provider.instId
                    : activeOrganisation.id,
                status: selectedStatus,
                sortBy: sortBy,
                sortOrder: sortOrder,
            });

            await requestBlobDownload(
                csv,
                `QualityRuleReport.${data.uuid.toLowerCase()}.${
                    selectedStatus === 0 ? 'inside' : 'outside'
                }.${new Date().toISOString()}.csv`,
            );
        }
    }, [
        activeOrganisation,
        data.provider,
        data.uuid,
        selectedStatus,
        sortBy,
        sortOrder,
    ]);

    const handleClickAllQualityRuleFailuresDownloadDialogContinue =
        async () => {
            setShowAllQualityRuleFailuresDownloadDialog(false);
            if (submissionId) {
                await requestAllQualityRuleFailuresDownload(submissionId);
            }
        };

    const handleClickDownloadAllReports = async () => {
        handleClickDownloadDialogOpen();
        const retrievedData = await credibilityApi.getAllReportDataZip({
            submissionId: data.uuid,
        });
        downloadRetrievedData(retrievedData);
    };

    const handleClickDownloadChapterReports = async (chapterId: number) => {
        handleClickDownloadDialogOpen();
        const retrievedData = await credibilityApi.getChapterReportDataZip({
            chapterId,
            submissionId: data.uuid,
        });
        downloadRetrievedData(retrievedData);
    };

    const downloadRetrievedData = ({
        contents,
        fileName,
    }: {
        contents: string;
        fileName: string;
    }) => {
        const a = document.createElement('a');
        a.href = contents;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        handleClickDownloadDialogClose();
    };

    const handleClickDownloadDialogOpen = () => {
        setShowDownloadDialog(true);
    };

    const handleClickDownloadDialogClose = () => {
        setShowDownloadDialog(false);
    };

    const handleClickAllQualityRuleFailuresDownloadDialogOpen = () => {
        setShowAllQualityRuleFailuresDownloadDialog(true);
    };

    const handleClickAllQualityRuleFailuresDownloadDialogClose = () => {
        setShowAllQualityRuleFailuresDownloadDialog(false);
    };

    const loadQualityRules = () => {
        loadValidationData(
            data.uuid,
            selectedStatus,
            sortBy,
            sortOrder,
            offset,
            limit,
            searchTerm,
            summaryData,
        );
        getPreviousSubmissions(
            data.uuid,
            data.collection.id,
            data.provider.instId,
        );
        getAllQualityResultsFileExists(data.uuid);
    };

    useEffect(() => {
        collectionsApi
            .getActivityLogEntries({
                submissionUuid: data.uuid,
                activityLogTypeId: AL_CREDIBILITY_REQUESTED,
            })
            .then(result => {
                if (result?.records?.length > 0) {
                    setHasAlreadyCompletedManualCredibility(true);
                    return;
                }
                setHasAlreadyCompletedManualCredibility(false);
            });
    }, [data.uuid]);

    useEffect(() => {
        // This prevents the quality rules accordion being empty when a user navigates back to the page with the accordion open
        loadQualityRules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [
        hasAlreadyCompletedManualCredibility,
        setHasAlreadyCompletedManualCredibility,
    ] = useState(false);
    const [credibilityDialogVisible, setCredibilityDialogVisible] =
        useState(false);
    const closeCredibilityDialog = () => {
        setCredibilityDialogVisible(false);
    };
    const handleCredibilityIssues = async () => {
        if (data.provider.riskCode !== '02') return;
        try {
            await submissionsApi.requestCredibility({
                submissionUuid: String(data.uuid),
                instId: String(data.provider.instId),
                collectionId: String(data.collection.id),
                fileName: String(data.fileName),
                providerName: currentProvider?.publishedName
                    ? currentProvider.publishedName
                    : '',
            });
            setHasAlreadyCompletedManualCredibility(true);
            closeCredibilityDialog();
        } catch (error) {
            console.error(error);
            setHasAlreadyCompletedManualCredibility(false);
            closeCredibilityDialog();
        }
    };

    useEffect(() => {
        const collectionEndDate = collection?.referencePeriodEnd || '';
        const date = new Date();
        const currentDate = `${date.getFullYear()}-${String(
            date.getMonth() + 1,
        ).padStart(2, '0')}-${date.getDate()}`;
        const collectionPassedExpiryDate =
            currentDate > collectionEndDate ? true : false;
        setcollectionExpired(collectionPassedExpiryDate);
    }, [collectionExpired, collection?.referencePeriodEnd]);

    const toggleCompare = useCallback(
        (toggle: boolean) => {
            setIsCompare(toggle);
            if (!toggle) {
                setSortBy('FAILURE');
                setSelectedPreviousSubmission(undefined);
                setHideUnchangedValues(false);
                setCompareValidations(undefined);
                loadValidationData(
                    data.uuid,
                    selectedStatus,
                    'FAILURE',
                    sortOrder,
                    0,
                    limit,
                    searchTerm,
                    summaryData,
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            data.uuid,
            limit,
            loadValidationData,
            searchTerm,
            selectedStatus,
            sortOrder,
            setHideUnchangedValues,
            setIsCompare,
            setSelectedPreviousSubmission,
            setSortBy,
        ],
    );

    return (
        <React.Fragment>
            <ConfirmationPopup
                isOpen={credibilityDialogVisible}
                title="Confirmation required!"
                onConfirm={handleCredibilityIssues}
                onCancel={closeCredibilityDialog}
            >
                <DialogContentText>
                    Are you sure you are ready for HESA to start raising issues
                    for these credibility reports?
                    <br />
                    <br />
                    You cannot undo this action.
                </DialogContentText>
            </ConfirmationPopup>
            <InformationPopup
                isOpen={showDownloadDialog}
                onConfirm={handleClickDownloadDialogClose}
                title="Download in progress"
            >
                <DialogContentText>
                    Your download is being prepared, please do not close this
                    tab or navigate away until the download completes.
                </DialogContentText>
                <CircularProgress aria-label="Circular loading animation" />
            </InformationPopup>

            <ConfirmationPopup
                isOpen={showAllQualityRuleFailuresDownloadDialog}
                onCancel={handleClickAllQualityRuleFailuresDownloadDialogClose}
                onConfirm={
                    handleClickAllQualityRuleFailuresDownloadDialogContinue
                }
                title="Confirmation required!"
            >
                <DialogContentText>
                    <p>
                        Please note that by using the ‘download all’ function,
                        you will extract a data file including any personal data
                        contained within your provider’s latest reports.
                    </p>
                    <p>
                        It is important that when downloading this data, your
                        provider must have in place appropriate processes to
                        ensure that personal data is processed securely, in
                        accordance with Data Protection Legislation (including
                        the provision of the Student Collection Notice) and your
                        agreement(s) with HESA.
                    </p>
                </DialogContentText>
            </ConfirmationPopup>
            <AccordionGroup>
                <Accordion
                    title="Summary"
                    id={accordions.summary.searchParam}
                    dataTestId={formatTestId('open accordion', 'summary')}
                    description="Overview of rules failures inside and outside of tolerance"
                >
                    <Summary
                        summaryData={summaryData}
                        setSummaryData={setSummaryData}
                        submissionId={submissionId}
                        instId={data.provider.instId}
                    />
                </Accordion>
                <Accordion
                    description="Quality report failures in data submission"
                    title="Quality Report"
                    id={accordions.quality.searchParam}
                    dataTestId={formatTestId(
                        'open accordion',
                        'quality report',
                    )}
                >
                    <div className={styles.qualityReportButtonWrapper}>
                        <RestrictAccess
                            allowPermissions={[
                                'collections.reports-validation-details',
                            ]}
                        >
                            <Button
                                className={`hdp-override ${styles.qualityReportButton}`}
                                disableRipple={true}
                                disabled={!allQualityResultsFileExists}
                                variant="contained"
                                onClick={
                                    handleClickAllQualityRuleFailuresDownloadDialogOpen
                                }
                                data-test-id={formatTestId(
                                    'downloadDetailedResults',
                                )}
                            >
                                Download Detailed Results
                            </Button>
                        </RestrictAccess>

                        <Button
                            className={`hdp-override ${styles.qualityReportButton}`}
                            disableRipple={true}
                            disabled={isCompare}
                            variant="contained"
                            onClick={handleClickDownloadCsv}
                            data-test-id={formatTestId('downloadResults')}
                        >
                            Download Results
                        </Button>

                        <RestrictAccess
                            allowPermissions={['collections.view-issue']}
                        >
                            <Button
                                disabled={isCompare}
                                disableRipple={true}
                                variant="contained"
                                to={
                                    data.providerIssuesImsUIUrl
                                        ? data.providerIssuesImsUIUrl
                                        : ''
                                }
                                target="_blank"
                                className={`hdp-override ${styles.qualityReportButton}`}
                                data-test-id={formatTestId('view all issues')}
                                component={Link}
                            >
                                View All Issues
                            </Button>
                        </RestrictAccess>
                    </div>

                    <div className={styles.actionsBarWrapper}>
                        <div className={styles.actionsBar}>
                            <div className={styles.searchInputWrapper}>
                                <TextField
                                    className={styles.searchInput}
                                    id="search-term"
                                    size="small"
                                    value={searchTerm}
                                    variant="outlined"
                                    onKeyDown={handleEnterKey}
                                    onChange={handleUpdateSearchTerm}
                                    placeholder="Search"
                                    data-test-id={formatTestId('search input')}
                                    inputProps={{
                                        'aria-label': 'Search bar',
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={handleClearSearch}
                                                aria-label="Clear search"
                                                data-test-id={formatTestId(
                                                    'clear search',
                                                )}
                                            >
                                                <HighlightOffIcon
                                                    style={{
                                                        color: '#23397f',
                                                    }}
                                                />
                                            </IconButton>
                                        ),
                                    }}
                                />
                                <Button
                                    aria-label="search"
                                    disableRipple={true}
                                    onClick={() => handleSearchSubmit()}
                                    className={styles.searchButton}
                                    data-test-id={formatTestId('search')}
                                >
                                    <Search
                                        onClick={() => handleSearchSubmit()}
                                        fontSize="small"
                                        className={styles.searchButtonIcon}
                                    />
                                </Button>
                            </div>
                            <FormControl
                                variant="outlined"
                                className={styles.columnSelectListWrapper}
                            >
                                <Select
                                    value={selectedStatus}
                                    onChange={handleChangeStatus}
                                    className={styles.columnSelectListInner}
                                    data-test-id={formatTestId('select status')}
                                >
                                    <MenuItem
                                        value={1}
                                        data-test-id={formatTestId(
                                            'status option',
                                            'outside tolerance',
                                        )}
                                    >
                                        Outside tolerance
                                    </MenuItem>
                                    <MenuItem
                                        value={0}
                                        data-test-id={formatTestId(
                                            'status option',
                                            'inside tolerance',
                                        )}
                                    >
                                        Inside tolerance
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                className={styles.switch}
                                control={
                                    <Switch
                                        className="hdp-override"
                                        disabled={
                                            noResultsFound ||
                                            !previousSubmissions?.length
                                        }
                                        data-test-id={formatTestId(
                                            'toggle',
                                            'isCompare',
                                        )}
                                        checked={isCompare}
                                        onChange={() =>
                                            toggleCompare(!isCompare)
                                        }
                                        inputProps={{
                                            'aria-label': 'compare',
                                        }}
                                    />
                                }
                                label="Compare"
                            />

                            <button
                                onClick={openDropDown}
                                className={styles.fakeDropDown}
                                disabled={
                                    !isCompare ||
                                    !previousSubmissions?.length ||
                                    noMatchingResults
                                }
                                data-test-id={
                                    !isCompare ||
                                    !previousSubmissions?.length ||
                                    noMatchingResults
                                        ? null
                                        : formatTestId('select submission')
                                }
                            >
                                <span className={styles.fakeDropdowninner}>
                                    <span>
                                        {checkIfObjectHasKeys(
                                            selectedPreviousSubmission,
                                        ) === true && selectedPreviousSubmission
                                            ? getSubmissionDisplay(
                                                  selectedPreviousSubmission,
                                              )
                                            : 'Select a submission'}
                                    </span>
                                    <ArrowDropDownSharp
                                        className={styles.fakeDropdownItemArrow}
                                    />
                                </span>
                            </button>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={closeDropDown}
                                className={styles.fakeDropdownMenu}
                            >
                                <div className={styles.fakeDropdowHeader}>
                                    <span
                                        className={styles.fakeDropdowHeaderId}
                                    >
                                        ID
                                    </span>
                                    <span
                                        className={styles.fakeDropdowHeaderDate}
                                    >
                                        Date
                                    </span>
                                </div>
                                {previousSubmissions?.map(
                                    (submission, index) => (
                                        <MenuItem
                                            onClick={() =>
                                                closeDropDown(submission)
                                            }
                                            key={crypto.randomUUID()}
                                            className={styles.fakeDropdownItem}
                                            data-test-id={formatTestId(
                                                'submission option',
                                                (index + 1).toString(),
                                            )}
                                        >
                                            {getSubmissionDisplay(submission)}
                                        </MenuItem>
                                    ),
                                )}
                            </Menu>
                            <FormControlLabel
                                className={styles.switch}
                                disabled={
                                    !checkIfObjectHasKeys(
                                        selectedPreviousSubmission,
                                    )
                                }
                                control={
                                    <Switch
                                        className="hdp-override"
                                        checked={hideUnchangedValues}
                                        onChange={toggleHideUnchanged}
                                        data-test-id={formatTestId(
                                            'toggle',
                                            'hide unchanged values',
                                        )}
                                        inputProps={{
                                            'aria-label':
                                                'hide unchanged values',
                                        }}
                                    />
                                }
                                label="Hide unchanged values"
                            />
                        </div>
                    </div>

                    <div>
                        {isCompare && selectedPreviousSubmission ? (
                            <QualityRulesCompare
                                data={compareValidations?.records}
                                pagingData={pagingCompareData}
                                sortHandler={handleCompareSortByClicked}
                                pageHandler={handleComparePageClicked}
                                activeSort={sortBy}
                                noResultsFound={noCompareResultsFound}
                                activeSortDirection={sortOrder}
                            />
                        ) : (
                            <QualityRules
                                collection={collection}
                                instId={data?.provider?.instId}
                                searchedEmptyResults={searchedEmptyResults}
                                noMatchingResults={noMatchingResults}
                                submissionId={data.uuid}
                                isLatestSubmission={data.isLatest}
                                data={validationRows}
                                pagingData={pagingData}
                                sortHandler={handleSortByClicked}
                                selectedStatus={selectedStatus}
                                pageHandler={handlePageClicked}
                                activeSort={sortBy}
                                noResultsFound={noResultsFound}
                                activeSortDirection={sortOrder}
                                disallowCreateIssues={
                                    !!collection?.disallowCreateIssues
                                }
                            />
                        )}
                    </div>
                </Accordion>
                <Accordion
                    id={accordions.credibility.searchParam}
                    title="Credibility Reports"
                    dataTestId={formatTestId(
                        'open accordion',
                        'credibility reports',
                    )}
                    description="Management information tables for quality assurance"
                >
                    <div className={styles.raiseCredibility}>
                        <RestrictAccess
                            allowPermissions={[
                                'collections.request-credibility-issues',
                            ]}
                        >
                            {data?.provider?.riskCode === '02' &&
                                collectionExpired && (
                                    <Button
                                        className={`${styles.raiseCredibilityButton} hdp-override--grey`}
                                        data-test="Download Reports"
                                        disableRipple={true}
                                        data-test-id={formatTestId(
                                            'credibility',
                                            'raise credibility issues',
                                        )}
                                        variant="contained"
                                        onClick={() => {
                                            setCredibilityDialogVisible(true);
                                        }}
                                        disabled={
                                            hasAlreadyCompletedManualCredibility
                                        }
                                    >
                                        Raise Credibility Issues
                                    </Button>
                                )}
                        </RestrictAccess>

                        <Button
                            className={styles.downloadButton}
                            data-test="Download Reports"
                            disableRipple={true}
                            data-test-id={formatTestId(
                                'download',
                                'credibility reports',
                            )}
                            color="primary"
                            variant="contained"
                            onClick={handleClickDownloadAllReports}
                        >
                            Download Reports
                        </Button>
                    </div>
                    <CredibilityReports
                        collection={collection}
                        submissionId={data.uuid}
                        instId={data.provider.instId}
                        handleClickDownloadChapterReports={
                            handleClickDownloadChapterReports
                        }
                    />
                </Accordion>
                <Accordion
                    id={accordions.additional.searchParam}
                    title="Additional Reports"
                    description="Further available reports for this collection"
                    dataTestId={formatTestId(
                        'open accordion',
                        'additional reports',
                    )}
                >
                    {additionalReportsUnavailable && (
                        <BannerBox
                            heading=""
                            upperText="Additional Collection Reports that we are waiting to release for 23056 Student will not appear in the available reports."
                            lowerText={
                                <span>
                                    For more information on the release of
                                    reports please visit the{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.hesa.ac.uk/collection/23056-support-guides#upcoming-releases-23056"
                                    >
                                        {' '}
                                        upcoming releases
                                    </a>{' '}
                                    in the 23056{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.hesa.ac.uk/collection/23056-support-guides"
                                    >
                                        support guides
                                    </a>
                                    .
                                </span>
                            }
                        />
                    )}

                    <AdditionalReports submissionId={data.uuid} />
                </Accordion>
            </AccordionGroup>
            <div className={styles.buttons}>
                <Button
                    className="hdp-override large"
                    disableRipple={true}
                    variant="contained"
                    onClick={() => navigateToStep('Submit')}
                    data-test-id="continue"
                >
                    Continue
                </Button>
                <Button
                    className="hdp-override large hdp-override--grey"
                    disabled={false}
                    disableRipple={true}
                    variant="contained"
                    onClick={() => navigateToStep('Processing')}
                    data-test-id="back"
                >
                    Back
                </Button>
                <RestrictAccess
                    allowPermissions={['collections.upload-submission-file']}
                >
                    <Button
                        className={`hdp-override large hdp-override--grey ${styles.uploadButton}`}
                        disabled={!canUploadNewSubmission}
                        disableRipple={true}
                        variant="contained"
                        onClick={newSubmission}
                        data-test-id={formatTestId('upload new file')}
                    >
                        Upload new file
                    </Button>
                </RestrictAccess>
            </div>
        </React.Fragment>
    );
};

export default Results;
