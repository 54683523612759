import { useQuery } from '@tanstack/react-query';

import { getAdditionalReportsQueryOptions } from './options';

export default function useAdditionalReports({
    submissionId,
}: {
    submissionId: string;
}) {
    const options = getAdditionalReportsQueryOptions({ submissionId });
    return useQuery(options);
}
