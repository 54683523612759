import { Link } from 'react-router-dom';
import { useUserContext } from 'components';
import { getSubmissionLinkForStep } from 'pages/Collections/Submission/utils';
import { LatestSubmission } from 'types/submission';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import { SubmissionCollection } from './utils';

import styles from './submissionLink.module.scss';

interface SubmissionLinkProps {
    collection: SubmissionCollection;
    submission: LatestSubmission;
    isNilReturn: boolean;
    institutionId: string;
    isHistoricResubmission: boolean;
    isOvt: boolean;
}

const SubmissionLink = ({
    collection,
    submission,
    isNilReturn,
    institutionId,
    isHistoricResubmission,
    isOvt,
}: SubmissionLinkProps) => {
    const { isStatutoryCustomer: isStatutoryCustomer } = useUserContext();

    if (!submission) return 'Not started';
    if (isNilReturn) {
        if (isHistoricResubmission) {
            return 'Resubmission required - historic amendment (Nil return)';
        } else return 'Nil return';
    }

    const submissionLink = getSubmissionLinkForStep({
        submissionId: submission.uuid,
        reference: collection?.reference,
        collectionId: collection?.id,
        instId: institutionId,
        status: submission.status.code,
        isOvt,
        isStatutoryCustomer,
    });

    if (!submissionLink) return 'Link not available';

    const { adminPath } = submissionLink;

    return (
        <Link
            className={styles.link}
            data-test-id={formatTestId(
                'link to',

                `${isOvt ? 'ovt ' : ''}submission state ${institutionId}`,
            )}
            to={{
                pathname: adminPath,
            }}
            state={{
                name: collection.name,
                reference: collection.reference,
            }}
        >
            {submission?.status?.name}
        </Link>
    );
};

export default SubmissionLink;
