import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import useCredibilityReportDownload from 'queries/credibility/useCredibilityReportDownload';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import styles from './downloadResultsButton.module.scss';

export default function DownloadResultsButton() {
    const { submissionId = '', reportCode = '' } = useParams();

    const { data } = useCredibilityReportDownload({
        submissionId,
        reportCode,
    });

    return (
        <Button
            className={styles.resultsButton}
            data-test-id={formatTestId('download results')}
            disableRipple
            variant="contained"
            component="a"
            download={data?.download}
            href={data?.href}
            disabled={!data?.href || !data?.download}
        >
            Download Results
        </Button>
    );
}
