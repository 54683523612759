import { MouseEvent, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import BannerBox from 'components/BannerBox/BannerBox';
import {
    HDPAccordion,
    HDPAccordionDetails,
    HDPAccordionGroup,
    HDPAccordionSummary,
} from 'components/HDPAccordion';
import useProviderCollections from 'queries/provider/useProviderCollections';
import { useUserContext } from 'src/components';
import { ACCORDION_IDS, PATHS } from 'src/constants/constants';
import { Collection } from 'src/types/collection';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import SubmissionAccordionActions from './SubmissionAccordionActions';
import SubmissionAccordionHeader from './SubmissionAccordionHeader';
import SubmissionsList from './SubmissionsList';
import { collectionIsOpenOrHistoricAmendment } from './utils';

import styles from './submissions.module.scss';

interface SubmissionsProps {
    isOvt?: boolean;
}

export const Submissions = ({ isOvt = false }: SubmissionsProps) => {
    const navigate = useNavigate();
    const [activityLogLink, setActivityLogLink] = useState('');
    const { activeOrganisation } = useUserContext();
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
        null,
    );

    const collectionStateFilters = isOvt
        ? ['open', 'historic amendment', 'validation']
        : ['open', 'closed', 'historic amendment'];

    const {
        data: collections,
        isLoading: collectionsLoading,
        isError: collectionsError,
        isSuccess: collectionsLoaded,
    } = useProviderCollections({
        instId: activeOrganisation.id,
        state: collectionStateFilters,
    });

    const openMenu = (
        event: MouseEvent<HTMLButtonElement>,
        collection: Collection,
    ) => {
        event.stopPropagation();

        const activityLogLink = generatePath(PATHS.PROVIDER_ACTIVITY_LOG, {
            collectionReference: collection.reference,
        });

        setMenuAnchorEl(event.currentTarget);
        setActivityLogLink(activityLogLink);
    };

    const closeMenu = () => {
        setActivityLogLink('');
        setMenuAnchorEl(null);
    };

    const pageTitle = isOvt ? 'Online Validation Tool' : 'Manage Submissions';

    return (
        <>
            <h1 className="heading--h1 margin--bottom-1" id="heading">
                {pageTitle}
            </h1>
            {isOvt && (
                <BannerBox
                    heading="Online validation tool"
                    upperText="This is the online validation tool (OVT). This tool is designed for providers to validate their files. Once your file is ready to be analysed you will need to upload it to the relevant live collection. Please note that only the latest uploaded file will be available to view."
                    lowerText={
                        <span>
                            If you need support please contact{' '}
                            <a href="mailto:liaison@hesa.ac.uk">
                                HESA Liaison.
                            </a>
                        </span>
                    }
                />
            )}
            <div className={styles.accordionheader}>
                <span className={styles.accordionHeaderName}>Name</span>
                <span className={styles.accordionHeaderHeading}>
                    Date range
                </span>
                <span className={styles.accordionHeaderHeading}>
                    {!isOvt ? 'Submissions' : ''}
                </span>
                <span className={styles.accordionHeaderHeading}>&nbsp;</span>
                <span className={styles.accordionHeaderHeading}>&nbsp;</span>
            </div>
            <Menu
                id="log-menu"
                classes={{
                    paper: 'hdp-override three-dot-menu',
                }}
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <MenuItem
                    onClick={() => {
                        if (activityLogLink) {
                            navigate(activityLogLink);
                        }
                    }}
                >
                    Activity log
                </MenuItem>
            </Menu>
            {collectionsLoading && (
                <CircularProgress aria-label="Circular loading animation" />
            )}
            {collectionsError && <p>Error getting collections</p>}
            {collectionsLoaded && (
                <HDPAccordionGroup>
                    {collections.map((collection: Collection) => (
                        <HDPAccordion
                            disabled={
                                !isOvt &&
                                !collectionIsOpenOrHistoricAmendment(collection)
                            }
                            data-test-id={formatTestId(
                                'openAccordion',
                                collection?.reference,
                            )}
                            key={`submissions-${collection.reference}`}
                            searchParamId={
                                ACCORDION_IDS.MANAGE_SUBMISSIONS.COLLECTION +
                                collection.reference
                            }
                        >
                            <HDPAccordionSummary
                                expandIcon={
                                    !isOvt &&
                                    !collectionIsOpenOrHistoricAmendment(
                                        collection,
                                    ) ? (
                                        <LockIcon />
                                    ) : (
                                        <ExpandMoreIcon />
                                    )
                                }
                                header={
                                    <SubmissionAccordionHeader
                                        collection={collection}
                                        isOvt={isOvt}
                                    />
                                }
                                size="md"
                                fontWeight="normal"
                                data-test-id={formatTestId(
                                    'open accordion',
                                    collection.id.toString(),
                                )}
                            >
                                <SubmissionAccordionActions
                                    collection={collection}
                                    openMenu={openMenu}
                                    isOvt={isOvt}
                                />
                            </HDPAccordionSummary>
                            <HDPAccordionDetails>
                                <SubmissionsList
                                    collection={collection}
                                    instId={activeOrganisation.id}
                                    isOvt={isOvt}
                                />
                            </HDPAccordionDetails>
                        </HDPAccordion>
                    ))}
                </HDPAccordionGroup>
            )}
        </>
    );
};

Submissions.displayName = 'Submissions';
