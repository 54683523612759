import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useOutletContext } from 'react-router-dom';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Paper,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useUserContext } from 'components';
import { DateTime } from 'luxon';
import { getActivityLogEntries } from 'services/api/collections/collections';
import AccessDenied from 'src/components/AccessDenied/AccessDenied';
import { RestrictAccess } from 'src/components/index';
import { AL_UPLOADED_VIA_UI } from 'src/constants/activityLogTypeIds';
import { PATHS } from 'src/constants/constants';
import { fileStatusById, fileStatusByKey } from 'src/constants/FileStatus';
import useUpdateSubmission from 'src/queries/submissions/useUpdateSubmission';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { requestSignOffFormFileDownload } from '../../utils';
import { useStepper } from '../Stepper/StepperContext';

import styles from './signOff.module.scss';

interface Submission {
    uuid: string;
    fileName: string;
    uploaded: string;
    fileHash: string;
    status: {
        id: number;
    };
    isLatest: boolean;
}

interface ActivityLog {
    records: { username: string }[];
}

// TO SEE THIS COMPONENT RENDERED IN THE APP:
// 1. Upload a submission as a provider submitter (e.g hdp.provider.submitter.bristol@test.com)
// 2. Wait for it to process and get through to Quality Assurance
// 3. Sign out and sign in as a HESA Admin user (hdp.hesa.admin@test.com), find the submission you just uploaded in Monitoring and go to the Submit step
// 4. Go through the submit process on that screen, then go back to monitoring and for the submission you uploaded, there will be a 3-dot menu with 'Approve'.
// You may have to refresh and do this again - this is due to some submissions requiring two approvals for risk
// purposes (though at time of writing, it does'nt explain this in the UI)
// 5. If the state goes to 'Awaiting SC Approval' you need to sign in as the sc ofs test user (hdp.sc.analyst.ofs@test.com) and do the
// same (Monitoring -> 3 dot menu for submission -> approve)
// 6. Now log back in as the provider submitter (hdp.provider.submitter.bristol@test.com), and go to the submission you uploaded.
// 7. You should now be able to see the 'Sign Off' step, which includes this component.
const SignOff = () => {
    const history = useNavigate();
    const { roles, isProvider, isAdmin } = useUserContext();
    const {
        data: submission,
        submissionId,
        setPageTitle,
        getSubmission,
    } = useOutletContext<{
        data: Submission;
        submissionId: string;
        setPageTitle: (title: string) => void;
        getSubmission: () => void;
    }>();
    const [termsChecked, setTermsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const isProviderSignOff = isProvider && !!roles.includes('sign-off');
    const canSignOff = submission.isLatest && (isAdmin || isProviderSignOff);
    const { navigateToStep } = useStepper();
    const { updateState } = useUpdateSubmission();

    const { data: activityLog, isFetching: activityLogLoading } =
        useQuery<ActivityLog>({
            queryKey: ['activity-log-uploaded', submission?.uuid],
            queryFn: () =>
                getActivityLogEntries({
                    submissionUuid: submission?.uuid,
                    activityLogTypeId: AL_UPLOADED_VIA_UI,
                }),
            enabled: Boolean(submission?.uuid),
            refetchInterval: false,
        });

    const user = useMemo(
        () => activityLog?.records?.at(0)?.username,
        [activityLog],
    );

    useEffect(() => {
        setPageTitle('Sign Off');
    }, [setPageTitle]);

    const HandleSubmit = () => {
        if (signOffRequested()) {
            handleDownload();
        }

        if (isSignedOff()) {
            const route = generatePath(
                isAdmin ? PATHS.MONITORING : PATHS.DASHBOARD,
            );
            history(route);
        }

        if (isApproved()) {
            setLoading(true);
            updateState(
                {
                    submissionId: submission.uuid,
                    statusId: fileStatusByKey('PROVIDER_SIGNOFF').id,
                    action: 'Provider sign-off',
                },
                {
                    onSettled: () => {
                        getSubmission();
                    },
                },
            );
        }
    };

    const signOffRequested = () =>
        fileStatusById(submission?.status.id).order ===
        fileStatusByKey('PROVIDER_SIGNOFF').order;

    const isApproved = () =>
        fileStatusById(submission?.status.id).order ===
        fileStatusByKey('APPROVED').order;

    const isSignedOff = () =>
        fileStatusById(submission?.status.id).order ===
        fileStatusByKey('SIGNED_OFF').order;

    const handleDownload = () => {
        requestSignOffFormFileDownload(submission.fileHash);
    };

    const canSubmit = () =>
        isSignedOff() || signOffRequested() || (canSignOff && termsChecked);

    useEffect(() => {
        if (
            fileStatusById(submission?.status.id).order ===
            fileStatusByKey('PROVIDER_SIGNOFF').order
        ) {
            setLoading(false);
        }
    }, [submission]);

    const getSubmitButtonLabel = () => {
        if (signOffRequested() === true) {
            return 'Download Sign-off Form';
        }
        if (isApproved() === true) {
            return 'Request Sign-Off';
        }
        if (isSignedOff() === true) {
            return isAdmin ? 'Return to monitoring' : 'Return to my Dashboard';
        }
    };

    return (
        <RestrictAccess
            allowPermissions={['collections.approval-and-sign-off']}
            render={() => <AccessDenied mt={8} />}
        >
            <div>
                <Paper className={styles.signOff}>
                    <h2 className={styles.heading}>Sign off</h2>{' '}
                    {isApproved() ? (
                        <>
                            {loading || activityLogLoading ? (
                                <div className={styles.loading}>
                                    <CircularProgress aria-label="Circular loading animation" />
                                </div>
                            ) : (
                                ''
                            )}

                            <p
                                className="margin--bottom-1"
                                data-test-id={formatTestId('submissionInfo')}
                            >
                                Your submission{' '}
                                <strong>{submission.fileName}</strong>, uploaded
                                on{' '}
                                <strong>
                                    {DateTime.fromISO(
                                        submission.uploaded,
                                    ).toFormat('dd LLLL y')}
                                </strong>{' '}
                                by <strong>{user}</strong> with Submission ID{' '}
                                <strong>{submissionId.slice(-8)}</strong> and
                                File ID <strong>{submission.fileHash}</strong>{' '}
                                needs to be signed off by your provider&apos;s
                                Accountable Officer (England and Wales) or Head
                                of Provider (Scotland and Northern Ireland).
                            </p>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className="hdp-override"
                                        checked={termsChecked}
                                        disabled={!canSignOff}
                                        data-test-id={
                                            canSignOff
                                                ? formatTestId('agree to terms')
                                                : null
                                        }
                                        onChange={() => {
                                            setTermsChecked(!termsChecked);
                                        }}
                                    />
                                }
                                label="I understand that this will start the sign-off procedure and will send via email, documentation and a notification to my provider's Accountable Officer (England and Wales) or Head of Provider (Scotland and Northern Ireland) that sign-off has been requested."
                            />
                        </>
                    ) : (
                        ''
                    )}
                    {signOffRequested() ? (
                        <>
                            <p
                                className="margin--bottom-1"
                                data-test-id={formatTestId('submissionInfo')}
                            >
                                Your submission{' '}
                                <strong>{submission.fileName}</strong>, uploaded
                                on{' '}
                                <strong>
                                    {DateTime.fromISO(
                                        submission.uploaded,
                                    ).toFormat('dd LLLL y')}
                                </strong>{' '}
                                by <strong>{user}</strong> with Submission ID{' '}
                                <strong>{submissionId.slice(-8)}</strong> and
                                File ID <strong>{submission.fileHash}</strong>{' '}
                                needs to be signed off by your provider&apos;s
                                Accountable Officer (England and Wales) or Head
                                of Provider (Scotland and Northern Ireland).
                            </p>
                            <p>
                                You can download the sign-off form by clicking
                                the button below. A copy of this form has been
                                emailed to your provider&apos;s Accountable
                                Officer or Head of Provider.
                            </p>
                        </>
                    ) : (
                        ''
                    )}
                    {isSignedOff() ? (
                        <>
                            <p data-test-id={formatTestId('submissionInfo')}>
                                Your submission{' '}
                                <strong>{submission.fileName}</strong>, uploaded
                                on{' '}
                                <strong>
                                    {DateTime.fromISO(
                                        submission.uploaded,
                                    ).toFormat('dd LLLL y')}
                                </strong>{' '}
                                by <strong>{user}</strong> with ID{' '}
                                <strong>{submissionId.slice(-8)}</strong> has
                                been signed off.
                            </p>
                        </>
                    ) : (
                        ''
                    )}
                </Paper>
                <div className={styles.buttons}>
                    <Button
                        className="hdp-override large"
                        data-test-id="submit"
                        disabled={!canSubmit()}
                        disableRipple={true}
                        variant="contained"
                        onClick={HandleSubmit}
                    >
                        {getSubmitButtonLabel()}
                    </Button>
                    <Button
                        className="hdp-override large hdp-override--grey"
                        data-test-id="back"
                        disabled={false}
                        disableRipple={true}
                        variant="contained"
                        onClick={() => navigateToStep('Approval')}
                    >
                        Back
                    </Button>
                </div>
                <div className="margin--top-1">
                    <span>
                        Please contact{' '}
                        <a
                            href="mailto:liaison@hesa.ac.uk"
                            data-test-id="emailLiaison"
                        >
                            HESA Liaison
                        </a>{' '}
                        if a resubmission is required.
                    </span>
                </div>
            </div>
        </RestrictAccess>
    );
};

export default SignOff;
