import React from 'react';
import { Box, Paper, TablePagination, Typography } from '@mui/material';
import { HDPAccordionGroup } from 'components/HDPAccordion';
import Loading from 'components/Loading/Loading';
import WithTestId from 'components/WithTestId/WithTestId';
import {
    SpecificationInterface,
    ValidationInterface,
} from 'queries/specifications/types';
import { PagingMetadata } from 'types/api';

import { FilterForm } from '../FilterForm';
import LoadingFailed from '../LoadingFailed/LoadingFailed';

import EditModal from './EditModal/EditModal';
import Validation from './Validation/Validation';

const TablePaginationWithTestId = WithTestId(TablePagination);

interface ValidationsProps {
    spec: SpecificationInterface;
    data: {
        validations: ValidationInterface[];
        pagingMetadata: PagingMetadata & { filteredTotal: number };
    };
    handleChangeRowsPerPage: (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => void;
    handleChangePage: (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => void;
    handleFilter: (filter: { filterTerm: string }) => void;
    handleTryAgain: () => void;
    validationParams: {
        filter: string;
        page: number;
        rowsPerPage: number;
    };
    status: 'pending' | 'error' | 'success';
}

const Validations = ({
    spec,
    data,
    handleChangeRowsPerPage,
    handleChangePage,
    handleFilter,
    handleTryAgain,
    validationParams,
    status,
}: ValidationsProps) => {
    const validations = data.validations.sort((a, b) => {
        return a.code < b.code ? -1 : 1;
    });

    return (
        <EditModal spec={spec} validations={validations}>
            <Paper square>
                <Box p={3}>
                    <FilterForm
                        initialFilterTerm={validationParams.filter}
                        onFilter={handleFilter}
                    />
                </Box>
            </Paper>
            {status === 'pending' && <Loading />}
            {status === 'error' && (
                <LoadingFailed
                    onTryAgain={handleTryAgain}
                    message="There was a problem loading the list of validations"
                />
            )}
            {status === 'success' && (
                <>
                    {validationParams.filter && (
                        <Paper square>
                            <Box px={3} pb={2}>
                                <Typography variant={'body2'}>
                                    <strong>
                                        {data.pagingMetadata.filteredTotal}
                                    </strong>{' '}
                                    validation
                                    {data.pagingMetadata.filteredTotal === 1
                                        ? ' rule'
                                        : ' rules'}{' '}
                                    found matching{' '}
                                    <strong>{validationParams.filter}</strong>
                                </Typography>
                            </Box>
                        </Paper>
                    )}
                    <HDPAccordionGroup spread={false}>
                        {validations.map((validation, index) => (
                            <Validation
                                key={`validation-${validation.id}-${index}`}
                                validation={validation}
                                spec={spec}
                            />
                        ))}
                    </HDPAccordionGroup>
                    <TablePaginationWithTestId
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        count={data.pagingMetadata.filteredTotal}
                        rowsPerPage={validationParams.rowsPerPage}
                        page={validationParams.page}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        testIdProps={{
                            backIconButtonProps: {
                                'data-test-id': 'previousPage',
                            },
                            nextIconButtonProps: {
                                'data-test-id': 'nextPage',
                            },
                        }}
                    />
                </>
            )}
        </EditModal>
    );
};

export default Validations;
